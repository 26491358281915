/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.meetingRow {
  padding-left: 0.5rem;
  background: #ffffff;
}
.meetingRow:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}
.meetingList {
  max-height: calc(75vh - 200px);
}
.toolbar > div {
  display: inline-block;
  vertical-align: -3px;
  margin-right: 0.3rem;
}
