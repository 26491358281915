/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.listContainer {
  padding: 0.5rem 1rem 1rem;
  height: 100%;
}
.listContainer > :global(.ant-list) {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.listContainer .link {
  cursor: pointer;
  color: #162740;
  font-weight: bold;
}
.listContainer .link .order {
  display: inline-block;
  padding-right: 0.5rem;
}
.unreaded {
  border-left: 3px solid #162740;
}
.pastDeadline {
  color: #f5222d;
}
.projectIcon {
  margin-right: 0.5rem;
}
.message {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}
.message .date {
  flex-grow: 1;
  text-align: right;
  margin-left: 0.8rem;
  text-wrap: nowrap;
}
