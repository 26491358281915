/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.filesGrid {
  max-height: 250px;
  min-width: 200px;
}
.documentRow {
  margin-bottom: 0.5rem;
  background: #ffffff;
}
.documentRow:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}
.pathDisplay {
  overflow: auto;
}
.centered {
  text-align: center;
}
.checkItem {
  min-width: 30px;
}
