
.resizer {
  --gap: .5rem;
  --margin: .25rem;
  width: calc(var(--gap) + (var(--margin) * 2));
  margin-inline: calc(-0.5rem - (var(--margin)));
  z-index: 100;
  border-left: solid 1px transparent;
  display: flex;
  align-items: center;
  justify-content: center;


  position: absolute;
  cursor: ew-resize;
  height: 100%;
  right: calc(var(--margin) * -1);
  top: 0;
  user-select: none;
  -webkit-user-select: none;
  background: transparent;
  transition: background 0.5s ease;
  overflow: hidden;
  color: #cccccc;
  font-size: 2rem;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
    z-index: 1;
  }

  &:hover {
    &:after {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
@primary-color: rgb(25,61,113);