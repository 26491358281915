/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.reportPanel {
  background-color: #ffffff;
  height: 100%;
}
.filterToolbar {
  flex: 0 1;
  margin-bottom: 1rem;
}
.reportWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pageContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.buttonToolbar {
  padding: 0.5rem 0 0.5rem 0.5rem;
}
