.modalBody {
  height: 100%;
  width: 100%;
}

.almostHideContent {
  max-height: 10px;
}

.modalBody iframe {
  height: 100%;
  width: 100%;
}

.modalError {
  text-align: center;
  display: block;
  padding: 1rem;
}

.bussySpinnerBox {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  width: 100px;
}

@primary-color: rgb(25,61,113);