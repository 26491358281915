/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.dropArea {
  margin-bottom: 1rem;
}
.container {
  overflow: auto;
  max-height: 260px;
  padding-bottom: 3px;
  composes: scrollbar from global;
}
.container :global(.ant-tree-node-content-wrapper),
.container :global(.ant-tree-node-content-wrapper),
.container :global(.ant-tree-node-content-wrapper) {
  width: calc(100% - 48px);
}
.itemTree {
  margin: 0;
  width: 100%;
  min-width: 120px;
  display: flex;
  flex: auto;
  height: auto;
  overflow: hidden;
}
.removed {
  text-decoration-line: line-through;
}
.item {
  padding: 0.25rem 0.5rem;
  margin: 0;
  width: 100%;
  min-width: 120px;
  display: flex;
  flex: auto;
  height: auto;
  overflow: hidden;
}
.content {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
}
.countInfo {
  color: #999999;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
.info {
  flex: 1;
  margin: 0 2rem 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.name {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.2rem 0.5rem 0.2rem 0.2rem;
}
.alreadyExists {
  color: #faad14;
}
.writable {
  color: #389e0d;
}
.fullAccess {
  color: #389e0d;
}
.forbidden {
  color: #cf1322;
}
.revision {
  color: #722ed1;
}
.errors {
  color: #f5222d;
}
.path {
  font-size: 0.8rem;
  font-style: italic;
  margin-top: -0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.size {
  font-size: 0.8rem;
}
.delete {
  margin-left: 1rem;
}
.title {
  display: flex;
  align-items: center;
  min-height: 1rem;
  font-weight: 600;
}
.titleWrap {
  display: block;
}
.input {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.inheritPermissions {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.inheritPermissions :global(.ant-switch) {
  margin: 0 0.5rem 0 0;
}
.statisticsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.infoRow {
  gap: 0 1rem;
  flex-wrap: wrap;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.statistic {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.statistic :global(.ant-btn-link) {
  color: inherit;
}
.statistic :global(.ant-btn-link) :global(.anticon) {
  margin: 0;
}
.checkingMessageLine {
  min-height: 1.7rem;
  display: block;
}
.menuMore {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  transform: scale(0.9);
}
.icons {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-right: 0.25rem;
}
.captureInput {
  display: none !important;
}
