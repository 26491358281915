.wideSelect {
  width: 100%;
}

.deleteButton {
  padding: 1.8rem 0 0;
}

.conditionLabel > :global(.ant-form-item-row) {
  flex-flow: column;
}

@primary-color: rgb(25,61,113);