/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.infoIcon {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.title {
  padding-top: 0.3rem;
}
.filterOrder {
  display: flex;
  flex-wrap: nowrap;
}
.container {
  background: #ffffff;
  border-radius: var(--box-border-radius);
}
.settingsDetail {
  padding-top: 1rem;
}
.settingsDetail :global(.ant-row) {
  padding: 0.5rem;
  line-height: 1.2rem;
  align-items: center;
}
.settingsDetail :global(.ant-row:nth-child(2n)) {
  background-color: #f1f1f1;
}
.settingsDetail :global(.ant-col + .ant-col) {
  text-align: center;
}
.menuLabel {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.menuLabel > :global(.ant-menu-title-content) {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
}
.projectName {
  flex-grow: 1;
}
.panelContent {
  margin-left: 1.5rem;
}
.badgedLabel {
  font-weight: bold;
  margin-right: 0.5rem;
}
