.roleUser {
  padding-bottom: 0.5rem;
  min-width: 500px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
}

.infoRow {
  padding-bottom: 0.25rem;
}

@primary-color: rgb(25,61,113);