.directoryListWrapper {
  max-height: 70vh;
  height: 50vh;
  width: 400px;
}

.directroryContentWrapper {
  width: 100%;
  margin-left: 1rem;
}

.responsiveTitle {
  display: none;
}

.documentSelectContainer {
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    & {
      flex-direction: column;

      .responsiveTitle {
        display: block;
        margin-bottom: 0.5rem;
      }
    }
    & .directroryContentWrapper {
      width: 100%;
      height: 40vh;
      margin-top: 1rem;
      margin-left: 0;
      background-color: #fefefe;
    }
    & .directoryListWrapper {
      height: 40vh;
      width: 100%;
    }
  }
}

@primary-color: rgb(25,61,113);