.panel {
  display: flex;
  flex-direction: column;
  padding: 0;
  min-width: 300px;
  min-height: 0;
  flex: 1;
}

.toolbar {
  outline: none;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  margin: 0 0 1rem 0;
  height: auto;
  min-height: 0;

  &.noMargin {
    padding: 0;
    overflow: hidden;
  }
}

.toolbarContent {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-right: 0.5rem;
}

.toolbarRight {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.toolbarLeft {
  flex: 1;
}

.content {
  composes: scrollbar from global;
  padding: 0 1rem 1rem;
  flex: 1;
  overflow: auto;

  &.noMargin {
    padding: 0;
  }
}

.toolbarButton {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  & > span {
    display: inline;
  }
}

@primary-color: rgb(25,61,113);