.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
  gap: 0.5rem;


  & :global(.dx-header-row) {
    & .columnA {
      background-color: #44546a !important;
      color: white !important;
    }
    & .columnB {
      background-color: #f79646 !important;
      color: black !important;
    }
  }}

.content {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}

.organizationSelect {
  min-width: 150px;
}
@primary-color: rgb(25,61,113);