.maskReorderable {
  &[role=listbox] {
    padding: 0.6rem;
    list-style: none;
    outline: none;
    min-height: 2rem;
    overflow: visible;
    border-radius: 0.2rem;
    background: #eaeaea;
  }

  &[role=listbox]:empty {
    box-sizing: border-box;
    border: 2px dashed gray;
    box-shadow: none;
    min-height: 2rem;
  }
}

.option {
  outline: none;
}

.option.focusVisible {
  box-shadow: inset 0 0 0 2px orange;
}

.option.dropTarget {
  border-color: transparent;
  box-shadow: inset 0 0 0 2px var(red);
}
@primary-color: rgb(25,61,113);