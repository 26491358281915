/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container {
  display: flex;
  flex-direction: column;
}
.container .title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2em;
  margin-top: 0.7rem;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: manual;
  text-wrap: balance;
}
.container .titleGroup {
  margin-bottom: 1rem;
  line-height: 1.2em;
}
.container .items {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.container .items.itemsLargeGap {
  gap: 2rem;
}
