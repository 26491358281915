/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.scrollableContent {
  height: auto;
  overflow: auto;
  composes: scrollbar from global;
  display: grid;
  gap: 0.5rem;
}
.tilesRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 0.5rem;
}
.defaultCursor {
  cursor: default !important;
}
