.layout {
  flex-wrap: wrap;
}

.select {
  width: 20dvw;
  min-width: 150px;
  max-width: 250px;
  @media (max-width: 600px) {
    width: 100%;
  }
}

@primary-color: rgb(25,61,113);