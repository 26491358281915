.Footer_footer__3bOqi {
  text-align: center;
  padding: 4px 50px;
  font-size: 12px;
}

.EmptyStyled_empty__s2OHq {
  justify-self: center;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 174px;
  justify-content: center;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.HeaderDropdown_container__O9AlX {
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.2rem;
  overflow: hidden;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
}

.LinkUnderscored_link__2K132:hover {
  text-decoration: underline;
}

.FlowLayout_flowLayout__26h-z {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.FlowLayout_flowLayout__26h-z.FlowLayout_wrap__3nV1c {
  flex-shrink: initial;
  flex-wrap: wrap;
}
.FlowLayout_flowLayout__26h-z.FlowLayout_wrap__3nV1c > * {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.FlowLayout_flowLayout__26h-z.FlowLayout_growFirst__dmjVS > *:first-child {
  flex-grow: 1;
}
.FlowLayout_flowLayout__26h-z.FlowLayout_growLast__3buX6 > *:last-child {
  flex-grow: 1;
}
.FlowLayout_flowLayout__26h-z.FlowLayout_fullWidth__9eHd8 {
  width: 100%;
}
.FlowLayout_flowLayout__26h-z .FlowLayout_gapSmall__m82_V {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.FlowLayout_flowLayout__26h-z .FlowLayout_gapNormal__23V7d {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.FlowLayout_flowLayout__26h-z .FlowLayout_gapMediumLarge__1PHMz {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.FlowLayout_flowLayout__26h-z .FlowLayout_gapLarge__3ZeLs {
  grid-gap: 1rem;
  gap: 1rem;
}
.FlowLayout_flowLayout__26h-z .FlowLayout_gapExtraLarge__1RcFs {
  grid-gap: 2rem;
  gap: 2rem;
}
.FlowLayout_flowLayout__26h-z .FlowLayout_gapNone__3UJPQ {
  grid-gap: 0;
  gap: 0;
}
.FlowLayout_alignLeft__2iKaT {
  justify-content: flex-start;
}
.FlowLayout_alignRight__2jpzS {
  justify-content: flex-end;
  margin-left: auto;
}

.AccessPermissionRequest_group__16Bqw {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.AccessPermissionRequest_button__2QNJx {
  margin-right: 1rem;
}

.Margin_top__1NOhg {
  margin-top: 1rem;
}
.Margin_left__apLuN {
  margin-left: 1rem;
}
.Margin_right__2kGMe {
  margin-right: 1rem;
}
.Margin_bottom__3iaXV {
  margin-bottom: 1rem;
}
.Margin_inFlex__3qvO6 {
  min-width: 0;
  min-height: 0;
}

.StackPanel_container__C3Cta {
  display: flex;
  align-content: flex-start;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior-y: none;
}
.StackPanel_vertical__1uvgI {
  flex-direction: column;
}
.StackPanel_center__b__OP {
  align-items: center;
}
.StackPanel_splitContent__17zV6 {
  justify-content: space-between;
}
.StackPanel_centerContent__1cFB_ {
  justify-content: center;
}
.StackPanel_endContent__Au7vI {
  justify-content: end;
}
.StackPanel_stretch__192El {
  flex: 1 1;
}
.StackPanel_paddingNormal__kFkK3 {
  padding: 1rem;
}
.StackPanel_scrollable__1rkZ9 {
  overflow-y: auto !important;
  overscroll-behavior-y: none;
}
.StackPanel_autoHeight__2eXwJ {
  height: auto;
  overflow: visible;
}
.StackPanel_autoWidth__1QG0N {
  width: auto;
}
.StackPanel_growLast__1gACz > div:last-child {
  flex-grow: 1;
}
.StackPanel_showOverflow__2PeM3 {
  overflow: visible;
}

.DirectoryForbiddenError_button__1rmxi {
  margin-top: 1rem;
  display: flex;
}
.DirectoryForbiddenError_alert__149NN {
  flex: 1 1;
  margin: 0 0 auto;
}

.ItemNotFoundErrorBox_button__18vp_ {
  margin-top: 1rem;
  display: flex;
}
.ItemNotFoundErrorBox_alert__2xQtJ {
  flex: 1 1;
  margin: 0 0 auto;
}

.DisplayName_displayName__2x5fB {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-weight: inherit;
}
.DisplayName_isWrap__31Cy5 {
  overflow: auto;
  white-space: pre-wrap;
}
.DisplayName_noWrap__3BbvQ {
  white-space: pre;
}
.DisplayName_strong__BWkfs {
  font-weight: 700;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.AccessLevel_character__2lUch {
  width: 40px;
  height: 17px;
  background-clip: border-box;
  display: flex;
}
.ant-rate-star-zero .AccessLevel_characterInner__3xO4F,
.ant-rate-star-focused .AccessLevel_characterInner__3xO4F {
  background-color: #d8d8d8;
}
.AccessLevel_characterInner__3xO4F {
  flex: 1 1;
  margin: 4px 0;
}
.AccessLevel_rateWrap__1oqQ0 {
  min-width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.5rem;
  flex: 1 1;
  min-height: 24px;
}
.AccessLevel_rateLabel__3Nv7z {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.5rem;
}
.AccessLevel_rate__2zVHh {
  vertical-align: middle;
  display: flex;
}
.AccessLevel_rate__2zVHh > li.ant-rate-star-full .AccessLevel_characterInner__3xO4F {
  background-color: #193d71;
}
.AccessLevel_rate__2zVHh.AccessLevel_coloured__3_NPP > li:nth-child(1).ant-rate-star-full .AccessLevel_characterInner__3xO4F {
  background-color: green;
}
.AccessLevel_rate__2zVHh.AccessLevel_coloured__3_NPP > li:nth-child(2).ant-rate-star-full .AccessLevel_characterInner__3xO4F {
  background-color: orange;
}
.AccessLevel_rate__2zVHh.AccessLevel_coloured__3_NPP > li:nth-child(3).ant-rate-star-full .AccessLevel_characterInner__3xO4F {
  background-color: red;
}
.AccessLevel_rate__2zVHh.AccessLevel_disabled__2p__x.AccessLevel_disabled__2p__x > li.ant-rate-star-full .AccessLevel_characterInner__3xO4F {
  background-color: gray;
}
.AccessLevel_rate__2zVHh.ant-rate .ant-rate-star > div:hover {
  -webkit-transform: scale(1.1, 1.2);
          transform: scale(1.1, 1.2);
}
.AccessLevel_rate__2zVHh.ant-rate-disabled .ant-rate-star > div:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.PathDisplay_container__2nKcV {
  align-items: center;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  justify-content: flex-end;
  display: inline-flex;
  text-align: right;
}
.PathDisplay_inline__1YTCP {
  display: inline-flex;
  flex-wrap: nowrap;
}
.PathDisplay_containerLeft__vWgUe {
  justify-content: flex-start;
  text-align: left;
}
.PathDisplay_arrow__31gDw {
  font-size: 0.8em;
  margin-left: 0.4em;
  margin-right: 0.4em;
}
.PathDisplay_root__2QfDX {
  margin-right: 0.4em;
}
.PathDisplay_part__HWqFv {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SpinBox_spin__lWUDr {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
}
.SpinBox_spin__lWUDr.SpinBox_fill__HzYlV {
  height: 100%;
}
.SpinBox_spin__lWUDr.SpinBox_fill__HzYlV > .ant-spin-container {
  height: 100%;
}
.SpinBox_spin__lWUDr.SpinBox_cover__3wrxL {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 !important;
}
.SpinBox_children__1tWJY {
  margin-top: 0.5rem;
}

.Modal_modal__Ol5IN {
  display: none;
  position: fixed;
  top: 5vh;
  left: 50px;
  right: 50px;
  bottom: 5vh;
  height: 90svh;
  width: 90svw;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  overflow: hidden;
  flex-direction: column;
  margin: auto;
  border-radius: 0.5rem;
  transition: width 233ms, height 200ms, left 200ms, right 200ms, box-shadow 200ms, border-radius 200ms;
}
.Modal_modal__Ol5IN.Modal_visible__1nw-a {
  display: flex;
}
.Modal_modal__Ol5IN.Modal_fullscreen__3xIXM {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100svh;
  width: 100svw;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
  border-radius: 0;
}
.Modal_content__2v2Xu {
  flex: 1 1;
  overflow: hidden;
}
.Modal_modal__Ol5IN .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
  margin-bottom: 0;
}
.Modal_header__1fntH {
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 40px;
  border-bottom: solid 1px #e8e8e8;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.Modal_header__1fntH > * {
  flex: 1 1;
}
.Modal_buttons__2lLnG {
  justify-content: flex-end;
}
@media screen and (max-width: 900px) {
  .Modal_modal__Ol5IN {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
    border-radius: 0;
  }
  .Modal_header__1fntH {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0.5rem;
  }
  .Modal_header__1fntH > * {
    flex: auto;
  }
  .Modal_buttons__2lLnG {
    justify-content: space-between;
    flex: 1 1;
    width: 100%;
  }
}
.Modal_title__2sGs5 {
  display: flex;
  justify-content: center;
  flex: 3 1;
  align-items: center;
  min-width: 300px;
}
.Modal_backdrop__2-cjP {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.SpinBoxCenter_spinWrapper__2cM3V {
  width: 100%;
  height: 100%;
  flex: 1 1;
  overflow: hidden;
}
.SpinBoxCenter_spinWrapper__2cM3V > .ant-spin-container {
  height: 100%;
  flex: 1 1;
}
.SpinBoxCenter_spinWrapper__2cM3V.SpinBoxCenter_flex__2vZp7 > .ant-spin-container {
  display: flex;
}
.SpinBoxCenter_spinWrapper__2cM3V.SpinBoxCenter_flex__2vZp7.SpinBoxCenter_vertical__2HW5X > .ant-spin-container {
  flex-direction: column;
}
.SpinBoxCenter_spinWrapper__2cM3V.SpinBoxCenter_overflowVisible__2jqfW {
  overflow: visible !important;
}
.SpinBoxCenter_spinWrapper__2cM3V.SpinBoxCenter_overflowVisible__2jqfW .ant-spin-container {
  overflow: visible !important;
}
.SpinBoxCenter_spin__2Q3Gg {
  max-height: none !important;
}

.HeaderInfoDropdown_icon__19ruR {
  padding: 4px;
  vertical-align: middle;
}
.HeaderInfoDropdown_noMargin__2g8Zz {
  margin: 0;
}
.HeaderInfoDropdown_keyTagsGroup__2ZijH {
  min-width: 135px;
  display: inline-block;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}
.HeaderInfoDropdown_keyShortcutItem__3FQIg {
  margin-bottom: 0.5rem;
  display: flex;
}
.HeaderInfoDropdown_listStyleTypeNone__1Fr59 {
  list-style-type: none;
}
.HeaderInfoDropdown_stretch__350t5 {
  flex: 1 1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.HeaderSearch_headerSearch__paFTW {
  display: flex;
  height: 0;
  min-height: 0;
  transition: height 0.3s;
  align-items: center;
  overflow: hidden;
  margin: 0;
  justify-content: center;
}
.HeaderSearch_headerSearch__paFTW.HeaderSearch_show__2loH9 {
  height: 40px;
  margin-bottom: 0.25rem;
}
.HeaderSearch_headerSearch__paFTW.anticon-search {
  font-size: 16px;
  cursor: pointer;
}
.HeaderSearch_headerSearch__paFTW .HeaderSearch_input__1a6el {
  min-width: 250px;
  width: 250px;
  margin-left: 8px;
  flex: 1 1;
}
@media (max-width: 768px) {
  .HeaderSearch_headerSearch__paFTW {
    font-size: 0.9rem;
  }
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.LocaleMenu_selected__3bM6G {
  background-color: #b3b3b3;
}
.LocaleMenu_flag__2s9Bf {
  vertical-align: center;
  font-size: 1em;
}
.LocaleMenu_groupTitle__BxO0y {
  cursor: default !important;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.UserIcon_sizeSmall__3-5mc {
  width: 18px;
  min-width: 18px;
  height: 18px;
  line-height: 17px;
  font-size: 18px;
  padding: 0;
  background: #999999;
}
.UserIcon_sizeMedium__2eb9Z {
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 22px;
  background: #999999;
}
.UserIcon_sizeLarge__gr4ny {
  width: 32px;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  font-size: 30px;
  background: #999999;
}
.UserIcon_extraLarge__1w-cs {
  width: 64px;
  min-width: 64px;
  height: 64px;
  line-height: 60px;
  font-size: 60px;
  background: #999999;
}

.DirectoriesPrefferedSelect_container__21klH .ant-tree li.filter-node > span {
  color: inherit !important;
}

.SearchPageSettings_settings__2XuTK {
  width: 400px;
}
.SearchPageSettings_settings__2XuTK .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  display: flex;
}
.SearchPageSettings_directorySelect__2OJDt {
  min-width: 200px;
  max-width: 500px;
}
.SearchPageSettings_sizeSlider__35U8- {
  width: 100px;
}

.HeaderUserDropdown_wrapper__3i3nO {
  min-width: 200px !important;
}
.HeaderUserDropdown_userInfo__1RiEK {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.HeaderUserDropdown_userInfoSection__39TOj {
  cursor: default;
  background: #ffffff !important;
}
.HeaderUserDropdown_userIcon__2dOut {
  display: block;
  text-align: center;
  padding: 0 0 0.5rem 0;
}
.HeaderUserDropdown_username__2l4ua {
  display: block;
  text-align: center;
  padding: 0;
  height: 1.5em;
  line-height: 1.5em;
}
.HeaderUserDropdown_dropdown__37vAZ {
  background: #ffffff;
  border-radius: 0.2rem;
  overflow: hidden;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  min-width: 10rem;
}
.HeaderUserDropdown_divider__29bdy {
  margin: 0;
}
.HeaderUserDropdown_badge__RIRG7 .ant-badge-count {
  -webkit-transform: none;
          transform: none;
  translate: none;
  right: 2px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectName_projectName__3kxAO {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 1 auto;
  min-width: 60px;
  padding: 0 1em;
  margin: 0.25rem 0;
  cursor: pointer;
  background: #e5eced;
  border-radius: 0.5rem;
  line-height: 1.75em;
}
@media (max-width: 768px) {
  .ProjectName_projectName__3kxAO {
    font-size: 0.9rem;
  }
}
.ProjectName_projectNameTitle__Vk2rM {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.ProjectName_projectDescription__1HdmC {
  margin-top: 0.2rem;
}
.ProjectName_tooltip__2yiBf {
  width: auto;
  max-width: 90%;
}

.MainModule\.Header_header__24i5e {
  --headerHeight: 40px;
  background: #ffffff;
  color: #193d71;
  padding: 0;
  height: auto;
  line-height: var(--headerHeight);
  min-height: var(--headerHeight);
  border-bottom: solid 1px #f1f1f1;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
  justify-content: center;
}
.MainModule\.Header_icon__3BP3l {
  padding: 4px;
  vertical-align: middle;
}
.MainModule\.Header_mainBar__3lE5F {
  max-width: 100%;
  height: var(--headerHeight);
}
.MainModule\.Header_hamburger__oJjV- {
  margin-left: 0.5rem;
}
.MainModule\.Header_hamburgerCollapsed__2wLHu {
  display: none;
}

.HeaderAppLogo_logo__1U-H0 {
  --logoSize: 1.2em;
  color: #fff;
  font-weight: 100;
  font-size: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.HeaderAppLogo_logo__1U-H0 .HeaderAppLogo_name__mKkFd {
  flex: 1 1;
}
.HeaderAppLogo_logo__1U-H0 > b {
  font-weight: 500;
}
.HeaderAppLogo_icon__2lB6G {
  margin: auto;
  border-radius: 0.05em;
  background-color: #ffffff;
  height: var(--logoSize);
  width: var(--logoSize);
  min-width: var(--logoSize);
  display: flex;
}
.HeaderAppLogo_icon__2lB6G > img {
  padding: 0.2em;
}

.Logo_logo__1JMn7 {
  --logoSize: 40px;
  color: #fff !important;
  height: var(--logoSize);
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.Logo_logo__1JMn7 .Logo_img__3whGa {
  border: 0;
  height: 100%;
  max-height: 100%;
  width: var(--logoSize);
  min-width: var(--logoSize);
  display: block;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
}
.Logo_logo__1JMn7 .Logo_text__1GnCi {
  flex: 1 1;
  line-height: 1;
  display: flex;
  word-break: break-word;
  white-space: normal;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.SideMenu_noLink__18l9z {
  color: #999999 !important;
}
.SideMenu_noLink__18l9z:hover {
  color: #999999 !important;
  cursor: default;
}
.SideMenu_menu__3Uy2R .ant-menu-submenu-active {
  color: #fff !important;
}
.SideMenu_menu__3Uy2R .ant-menu-item {
  margin: 0;
}
.SideMenu_menu__3Uy2R .ant-menu:not(.ant-menu-inline-collapsed) .SideMenu_subMenu__35VJZ {
  padding-left: 3rem !important;
}
.SideMenu_menu__3Uy2R .ant-menu-sub {
  background: #162740 !important;
}
.SideMenu_menu__3Uy2R .ant-menu-item-selected {
  background: #193d71 !important;
  color: #ffffff !important;
  width: auto;
}
.SideMenu_sider__22P8C {
  flex: 1 1;
  overflow: auto;
  background: #162740;
}
.SideMenu_sider__22P8C .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.SideMenu_menu__3Uy2R {
  flex: auto;
  background: transparent !important;
}
.SideMenu_menu__3Uy2R .ant-menu-item:not(.ant-menu-item-disabled):hover {
  text-decoration: underline !important;
}
.SideMenu_menuBottom__3Id7F {
  background: transparent !important;
}
.SideMenu_menuItemCompany__3lW4c {
  display: flex;
  align-items: center;
  font-size: 12px !important;
  margin: 0.5rem 1rem;
}
.SideMenu_hubLogo__1No6Y {
  margin: 1rem;
}
.SideMenu_spacer__23b2k {
  height: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-height: 0;
  min-height: 0;
  border-bottom: solid 2px #193d71;
}

.AdminPage_wrapper__2pUi4 {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}
.AdminPage_menu__2xPSf {
  display: flex;
  width: 200px;
  overflow: auto;
}
.AdminPage_mainContent__39ulA {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  padding: 1rem 0 0.5rem 0;
}
.AdminPage_mainContent__39ulA h1 {
  padding-left: 16px;
}

.AdminPageStressTest_label__GDAdp {
  font-weight: bold;
}
.AdminPageStressTest_value__3fQwi {
  margin-top: 0.5rem;
}
.AdminPageStressTest_param__1xGUF {
  margin-top: 0.5rem;
}
.AdminPageStressTest_item__3mkNN {
  margin-right: 1rem;
}
.AdminPageStressTest_main__1CCSQ {
  margin-left: 1rem;
  overflow: auto !important;
}

.PageContent_title__1IWAu.PageContent_title__1IWAu {
  margin: 0 0 0.5rem 0;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: flex-start;
  min-height: 2rem;
}
.PageContent_title__1IWAu.PageContent_title__1IWAu .PageContent_headline__1toZM {
  margin: 0;
  font-weight: 600;
  line-height: 1em;
  font-size: 1rem;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
}
.PageContent_content__1lX-h {
  margin: 0;
  display: flex;
  flex: 1 1;
  overflow-x: auto;
}
@media (max-width: 1000px) {
  .PageContent_title__1IWAu.PageContent_title__1IWAu .PageContent_headline__1toZM {
    font-size: 1rem;
    text-transform: uppercase;
  }
}
@media (max-width: 600px) {
  .PageContent_title__1IWAu.PageContent_title__1IWAu {
    margin: 0 0.5rem 0.5rem 0.5rem;
  }
  .PageContent_title__1IWAu.PageContent_title__1IWAu .PageContent_headline__1toZM {
    font-size: 13px;
  }
  .PageContent_content__1lX-h {
    margin: 0 0 0 0;
  }
}

.FormModalWrapper_main__2yXN2 {
  display: flex;
  width: 100%;
}
.FormModalWrapper_elipsis__1UhND {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.5rem;
  align-items: flex-start;
  display: flex;
  width: 100%;
}

.UserTransfer_groupMenu__2yXGY {
  background-color: white;
  overflow-y: auto;
  max-height: 400px;
}
.UserTransfer_transferList__34P1V {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.UserTransfer_transferList__34P1V > .ant-transfer-list {
  display: inline-flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  padding: 0;
}
.UserTransfer_transferList__34P1V > .ant-transfer-list > .ant-transfer-list-header {
  position: inherit;
}
.UserTransfer_transferList__34P1V > .ant-transfer-list > .ant-transfer-list-body {
  display: inline-flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
}
.UserTransfer_transferList__34P1V > .ant-transfer-list > .ant-transfer-list-footer {
  position: inherit;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.GeneralSettingsContainer_container__1ZiKz {
  display: flex;
  flex-direction: column;
}
.GeneralSettingsContainer_container__1ZiKz .GeneralSettingsContainer_title__2JWn8 {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2em;
  margin-top: 0.7rem;
  overflow-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: manual;
          hyphens: manual;
  text-wrap: balance;
}
.GeneralSettingsContainer_container__1ZiKz .GeneralSettingsContainer_titleGroup__3eTM0 {
  margin-bottom: 1rem;
  line-height: 1.2em;
}
.GeneralSettingsContainer_container__1ZiKz .GeneralSettingsContainer_items__1UqEF {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.GeneralSettingsContainer_container__1ZiKz .GeneralSettingsContainer_items__1UqEF.GeneralSettingsContainer_itemsLargeGap__BBevb {
  grid-gap: 2rem;
  gap: 2rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.GeneralSettingsItem_listItem__1zQEH {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  border-radius: 0.25rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  transition: background-color 0.3s ease-in-out, outline-color 0.3s ease-in-out;
  outline-width: 0;
  outline-offset: -2px;
  outline-color: transparent;
}
.GeneralSettingsItem_listItem__1zQEH.GeneralSettingsItem_selectable__nZPuP {
  cursor: pointer;
}
.GeneralSettingsItem_listItem__1zQEH.GeneralSettingsItem_selected__1ZzUe:not(.GeneralSettingsItem_disabled__2XJZV) {
  outline: 2px solid #193d71;
}
.GeneralSettingsItem_listItem__1zQEH.GeneralSettingsItem_disabled__2XJZV {
  background: #e8e8e8;
  color: #666666;
}
.GeneralSettingsItem_listItem__1zQEH.GeneralSettingsItem_selectable__nZPuP:not(.GeneralSettingsItem_disabled__2XJZV):hover {
  background-color: #dddddd;
}
.GeneralSettingsItem_listItemContent__2x-DR {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.25rem 1rem;
  gap: 0.25rem 1rem;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  flex-wrap: wrap;
}
.GeneralSettingsItem_wrap__3EcUK .GeneralSettingsItem_listItemContent__2x-DR {
  flex-wrap: wrap;
}
.GeneralSettingsItem_listItemBody__wmI6s {
  flex: 1 1 auto;
  overflow: hidden;
}
.GeneralSettingsItem_listItemInput__1ttos {
  margin-left: auto;
}
.GeneralSettingsItem_listItemIcon__1ytcj {
  width: 2rem;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.GeneralSettingsItem_listItemHeader__2xtpi {
  display: flex;
  flex-direction: column;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  flex: 1 1;
  min-width: 120px;
  overflow-wrap: anywhere;
}
.GeneralSettingsItem_listItemHeader__2xtpi .GeneralSettingsItem_listItemTitle__2QQKV {
  font-weight: bold;
  line-height: 1.2em;
}
.GeneralSettingsItem_listItemHeader__2xtpi p {
  line-height: 1.2rem;
  margin: 0;
}
.GeneralSettingsItem_contentBody__2LQQn {
  display: flex;
}
.GeneralSettingsItem_listItemActions__1udkg {
  flex: 0 0 auto;
  align-self: center;
  display: none;
}
.GeneralSettingsItem_listItemActionButton__R5UrF {
  margin-right: 8px;
}
.GeneralSettingsItem_description__2G3It {
  white-space: pre-wrap;
}

.UsersItem_wrapper__wac7v {
  display: flex;
  flex: 1 1;
  align-items: center;
  padding: 0.3rem;
  overflow: auto;
}
.UsersItem_noPadding__1kOXv {
  padding: 0;
}
.UsersItem_avatar__2FFXE {
  margin: 0;
}
.UsersItem_body__1jDJN {
  flex: 1 1;
  align-items: stretch;
  line-height: 1.3em;
  margin-left: 0.5rem;
  overflow: hidden;
  min-width: 4rem;
  display: flex;
  flex-direction: column;
}
.UsersItem_name__1v42I {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.UsersItem_name__1v42I .UsersItem_username__1PKKU {
  margin-left: 1.2rem;
}
.UsersItem_description__3vJ2s {
  font-weight: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.UsersItem_activity__2-HUH {
  margin-left: auto;
}
.UsersItem_usernameContainer__HqlUb {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -0.3em;
  line-height: 1.5em;
  position: relative;
}
.UsersItem_usernameContainer__HqlUb .UsersItem_username__1PKKU {
  margin-left: 1.2rem;
}
.UsersItem_emailIcon__2ZDhM {
  position: absolute;
  margin-right: 0.5rem;
  top: 0.25rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

.Panel_panel__3Ox22 {
  display: flex;
  flex-direction: column;
  padding: 0;
  min-width: 300px;
  min-height: 0;
  flex: 1 1;
}
.Panel_toolbar__DWI4h {
  outline: none;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin: 0 0 1rem 0;
  height: auto;
  min-height: 0;
}
.Panel_toolbar__DWI4h.Panel_noMargin__FFA1L {
  padding: 0;
  overflow: hidden;
}
.Panel_toolbarContent__HxjLL {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-right: 0.5rem;
}
.Panel_toolbarRight__1DIqf {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.Panel_toolbarLeft__3FzPq {
  flex: 1 1;
}
.Panel_content__2_Kkl {
  padding: 0 1rem 1rem;
  flex: 1 1;
  overflow: auto;
}
.Panel_content__2_Kkl.Panel_noMargin__FFA1L {
  padding: 0;
}
.Panel_toolbarButton__Lr6Wu {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.Panel_toolbarButton__Lr6Wu > span {
  display: inline;
}

.AspeHubSettingsPage_wrapper__2Kd2o {
  display: flex;
  flex: 1 1;
  overflow: hidden;
  background: #f1f1f1;
  grid-gap: 1rem;
  gap: 1rem;
}
.AspeHubSettingsPage_menu__3U_Cx {
  display: flex;
  width: 200px;
  overflow: auto;
}
.AspeHubSettingsPage_menu__3U_Cx .ant-menu-inline {
  background: transparent !important;
}
.AspeHubSettingsPage_menu__3U_Cx .ant-menu-item {
  border-radius: 0.25rem;
}
.AspeHubSettingsPage_menu__3U_Cx .ant-menu-item::after {
  top: 3px;
  bottom: 3px;
  border: none;
  width: 4px;
  right: 3px;
  border-radius: 2px;
  background: #193d71;
}
.AspeHubSettingsPage_menu__3U_Cx .ant-menu-item:hover {
  background: #ffffff !important;
  color: #193d71 !important;
  text-decoration: underline;
}
.AspeHubSettingsPage_menu__3U_Cx .ant-menu-item-selected {
  background: #ffffff !important;
  color: #193d71 !important;
  font-weight: 700;
}
.AspeHubSettingsPage_menu__3U_Cx .ant-menu-item-selected::after {
  top: 3px;
  bottom: 3px;
  border: none;
  width: 4px;
  right: 3px;
  border-radius: 2px;
  background: #193d71;
}
.AspeHubSettingsPage_mainContent__2w440 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  padding: 1rem 0 0.5rem 0;
  background: transparent;
}
.AspeHubSettingsPage_mainContent__2w440 h1 {
  padding-left: 16px;
}
.AspeHubSettingsPage_content__1Qnqz {
  display: flex;
  flex: 1 1;
  overflow-x: auto;
}
.AspeHubSettingsPage_item__i2_8q {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}
.AspeHubSettingsPage_content__1Qnqz .AspeHubSettingsPage_item__i2_8q {
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #e8e8e8;
}
.AspeHubSettingsPage_content__1Qnqz .AspeHubSettingsPage_item__i2_8q:first-child {
  padding-left: 0;
  border-left: none;
}
.AspeHubSettingsPage_itemToolbar__3x3hM {
  outline: none;
  margin-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
}
.AspeHubSettingsPage_itemTabs__1yzuX {
  display: flex;
  flex-direction: column;
}
.AspeHubSettingsPage_itemContent__1tK-j {
  overflow-y: auto;
  padding: 8px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DatePickerWithHolidays_freeDay__FgKnu {
  background-color: #b3b3b3;
}
.DatePickerWithHolidays_danger__1g2SS {
  outline: 2px solid #fa541c;
  outline-offset: -2px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.InlineDateRangePicker_rangeInput__SeWcO {
  width: 580px;
  display: flex;
  justify-content: space-between;
}
.InlineDateRangePicker_cellInRange__hfvEJ {
  background-color: #e5e5e5;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DocumentsGridHeader_documentsHeader__3TrAU {
  padding: 0.25rem 0.5rem;
}
.DocumentsGridHeader_documentsHeader__3TrAU.DocumentsGridHeader_noPadding__26Xcl {
  padding: 0;
}
.DocumentsGridHeader_documentsHeader__3TrAU.DocumentsGridHeader_marginBottom__25k8i {
  margin-bottom: 0.5rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.StyledBox_styledBox__2g9l3 {
  background-color: #ffffff;
  border-radius: var(--box-border-radius);
  border-bottom: 1px solid #e6e8f1;
}
.StyledBox_flex__3sJIr {
  flex: 1 1;
  display: flex;
  overflow: hidden;
  min-height: 0;
}
.StyledBox_padding__2pqe0 {
  padding: 0.25rem 0.5rem;
}

.MasterDetailContent_container__S3CTe {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.MasterDetailHeader_title__2zn3q {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: flex-start;
  min-height: 2rem;
}

.BackButton_backButton__2TdZL {
  min-width: 2rem;
}

.MasterDetailsView_masterItem__CQD2_ {
  min-width: 200px;
  display: flex;
  position: relative;
  flex: 1 1;
  justify-content: stretch;
}
.MasterDetailsView_masterItem__CQD2_.MasterDetailsView_noFlex__19ZQw {
  flex: 0 1;
}
.MasterDetailsView_masterItem__CQD2_.MasterDetailsView_showFirstPart__354eN:first-child {
  flex: 1 1;
}
.MasterDetailsView_masterItem__CQD2_.MasterDetailsView_hideItem__2sKYu {
  display: none;
  overflow: hidden;
  flex: 0 1;
}
.MasterDetailsView_visibleItem__1v3as {
  width: 100%;
}
.MasterDetailsView_wrapper__1OXAm {
  background: #f1f1f1;
}

.AuditLogDetailGrid_table__32_7G {
  margin-bottom: 8px;
}
.AuditLogDetailGrid_gridBody__Rl73B {
  overflow: auto;
  min-width: 800px;
}
.AuditLogDetailGrid_highlighted__2zHbD {
  outline: 3px solid #424f95;
  margin: 3px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MultiUserAvatar_avatarContainer__3lUAN {
  position: relative;
  display: flex;
}
.MultiUserAvatar_avatarContainer__3lUAN.MultiUserAvatar_clickable__3U23i {
  cursor: pointer;
}
.MultiUserAvatar_hasOwner__1ASVb {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0.5em;
  height: 0.5em;
  border: solid 2px #162740;
  background-color: #ffffff;
  border-radius: 50%;
}
.MultiUserAvatar_hasOwner__1ASVb.MultiUserAvatar_withUser__3xUYz {
  background-color: #162740;
}
.MultiUserAvatar_popoverContent__1kq-z > *:not(:last-child) {
  margin-bottom: 0.3rem;
}

.UserTooltipDetail_title__33RAD {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.SettingsBox_container__w0gWQ {
  display: flex;
  flex-direction: column;
  tab-index: 0;
  border-radius: var(--box-border-radius);
  background-color: #f1f1f1;
  border-bottom: 1px solid #e6e8f1;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
}
.SettingsBox_container__w0gWQ .ant-form-item {
  margin-bottom: 0;
}

.AuditLogRow_mainItem__1CtrU {
  margin-left: 8px;
  flex-grow: 1;
}

.AuditLogGrid_table__aZl7x {
  margin-bottom: 8px;
}

.AuditLogModal_gridBody__28yN- {
  overflow: auto;
  min-width: 300px;
}

.Label_label__1dski {
  font-weight: 500;
}
.Label_label__1dski.Label_dark__2PuZt,
.Label_label__1dski.Label_dark__2PuZt a {
  color: rgba(0, 0, 0, 0.65);
}

.LabelsInput_select__Nn--w {
  width: 100%;
}
.LabelsInput_select__Nn--w li.ant-select-selection__choice {
  background-color: #fff;
  border: 1px solid transparent;
  padding: 0;
}
.LabelsInput_select__Nn--w li.ant-select-selection__choice .ant-tag {
  padding-right: 18px;
  margin-right: 0;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.PrimaryFileInput_item__1luO5 {
  border: 1px solid #d9d9d9;
  padding: 16px;
}
.PrimaryFileInput_item__1luO5:hover {
  border: 1px solid #193d71;
}
.PrimaryFileInput_content__3sBTR {
  display: flex;
  align-items: center;
}
.PrimaryFileInput_icon__2Lccd {
  font-size: 32px;
}
.PrimaryFileInput_info__Gvibb {
  flex: 1 1;
  margin: 0 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PrimaryFileInput_name__1lBwd {
  font-size: 16px;
  font-weight: 600;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.SingleFileInput_item__1C6Q5 {
  border: 1px solid #d9d9d9;
  padding: 16px;
}
.SingleFileInput_item__1C6Q5:hover {
  border: 1px solid #193d71;
}
.SingleFileInput_content__RUyvt {
  display: flex;
  align-items: center;
}
.SingleFileInput_icon__12iYs {
  font-size: 16px;
}
.SingleFileInput_info__WNb7_ {
  flex: 1 1;
  margin: 0 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.SingleFileInput_name__17Y4O {
  font-size: 16px;
  font-weight: 600;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.RevisionNumberTag_badge__5lOa2 {
  padding: 0 5px;
  height: 24px;
  white-space: nowrap;
}
.RevisionNumberTag_icon__2q95o {
  margin-left: 3px;
}
.RevisionNumberTag_stateTitle__9OihT {
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}
.RevisionNumberTag_number__39jIg {
  line-height: 1rem;
  height: 1rem;
}
.RevisionNumberTag_workInProgress__15RJ0 {
  color: #1890ff;
  background: #e6f7ff;
}
.RevisionNumberTag_shared__30DIy {
  color: #faad14;
  background: #fffbe6;
}
.RevisionNumberTag_rejected__1g9Qm {
  color: #f5222d;
  background: #fff1f0;
}
.RevisionNumberTag_published__1zDHc {
  color: #389e0d;
  background: #f6ffed;
}
.RevisionNumberTag_archived__2j7by {
  color: #9254de;
  background: #f9f0ff;
}

.StateIcon_badge__293yN {
  border: solid 1px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  font-weight: 600;
  background: #ffffff;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.RoleUsageError_container__1xuWQ {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
}
.RoleUsageError_title__G2c3F {
  margin-bottom: 0.5rem;
}

.RolesList_roleName__3RRKh {
  word-break: break-all;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.RoleInfo_roleInfo__3Rpmw {
  display: flex;
  flex: 1 1;
  align-items: center;
  padding: 0.3rem;
  overflow: auto;
  min-width: 200px;
}
.RoleInfo_roleUserIcon__cP7e8 {
  display: flex;
  position: relative;
}
.RoleInfo_name__EUavC {
  flex: 1 1;
  line-height: 1.1em;
  margin-left: 0.5rem;
  overflow: hidden;
  min-width: 4rem;
  display: flex;
  flex-direction: column;
}
.RoleInfo_usernameContainer__-LB8W {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -0.3em;
  line-height: 1.3rem;
  position: relative;
}
.RoleInfo_withIcon__6Hili {
  margin-left: 1.2rem;
}
.RoleInfo_roleIcon__2fdCE {
  position: absolute;
  margin-right: 0.5rem;
  top: 0.25rem;
}
.RoleInfo_userIcon__3l0Bg {
  position: absolute;
  margin-right: 0.5rem;
  top: 0.25rem;
}
.RoleInfo_emailIcon__lsxQq {
  position: absolute;
  margin-right: 0.5rem;
  top: 0.25rem;
}
.RoleInfo_roleName__3xBnw {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3rem;
  margin-top: -0.15em;
  position: relative;
  white-space: nowrap;
}
.RoleInfo_noUserIcon__dT2nW {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: dashed 1px #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RoleInfo_associatedOnlyIcon__Z0ipv {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 1px #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RoleInfo_noUser__3omH2 {
  margin-top: -0.2em;
  font-style: italic;
  font-weight: 400;
}
.RoleInfo_noActiveUser__3sSzS {
  color: red;
}

.RoleSelect_roleSelect__u_-Su {
  width: 100%;
  height: auto;
}
.RoleSelect_roleSelect__u_-Su .ant-select-selector.ant-select-selector {
  height: auto;
}
.RoleSelect_roleSelect__u_-Su .ant-select-selection-search {
  display: flex;
  align-items: center;
}
.RoleSelect_roleSelect__u_-Su .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ImageBox_box__dt3UV {
  position: relative;
  background-color: #e1e2de;
  background: linear-gradient(0deg, #e1e2de 0%, #d9e1ea 100%);
}
.ImageBox_box__dt3UV .ImageBox_spin__ipxpo {
  width: 100%;
  height: 100%;
}
.ImageBox_box__dt3UV .ImageBox_spin__ipxpo > .ant-spin-container {
  height: 100%;
}
.ImageBox_box__dt3UV img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.DocumentThumbnail_thumbnail__30s24 {
  width: 55px;
  align-self: stretch;
  margin-right: 1rem;
  flex: 0 0 auto;
  border-radius: 0.25rem;
  overflow: hidden;
}
.DocumentThumbnail_thumbnail__30s24 .anticon {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DocumentThumbnail_clickable__3YUgc {
  cursor: pointer;
}
.DocumentThumbnail_clickable__3YUgc:hover {
  outline: solid 1px #193d71;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DocumentCheckbox_checkbox__oqULp {
  display: flex;
  cursor: pointer;
  position: relative;
  left: 0;
  top: 0;
  width: 30px;
  bottom: 0;
  min-height: 32px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 0 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
.DocumentCheckbox_checkbox__oqULp:before {
  content: '';
  display: flex;
  left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  border: solid 2px #b3b3b3;
  background: #ffffff;
  color: #b3b3b3;
  font-weight: 900;
}
.DocumentCheckbox_checkbox__oqULp.DocumentCheckbox_semiSelected__3XODv.DocumentCheckbox_checkbox__oqULp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.DocumentCheckbox_checkbox__oqULp.DocumentCheckbox_semiSelected__3XODv:before {
  display: block;
  color: #ffffff;
  border: solid 4px #193d71;
}
.DocumentCheckbox_checkbox__oqULp.DocumentCheckbox_selectedCheckbox__X2Up5.DocumentCheckbox_checkbox__oqULp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.DocumentCheckbox_checkbox__oqULp.DocumentCheckbox_selectedCheckbox__X2Up5:before {
  display: block;
  color: #ffffff;
  background: #193d71;
  border: solid 2px #ffffff;
}
.DocumentCheckbox_checkbox__oqULp.DocumentCheckbox_disabled__34li8:before {
  color: #ffffff;
  background: #b3b3b3;
  border: solid 2px #999999;
  cursor: not-allowed;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.StyledRow_wrapper__1UuGW {
  padding: 2px 0;
  height: 100%;
  min-width: 300px;
}
.StyledRow_row__2zpfi {
  border-radius: 5px;
  background: #ffffff;
}
.StyledRow_row__2zpfi.StyledRow_active__25tAH {
  outline: 2px solid #193d71;
  outline-offset: -2px;
  background: #ffffff;
}
.StyledRow_row__2zpfi.StyledRow_invalidTemplate__2apPP {
  border: solid #fba8c2 10px;
  background-color: #fba8c2;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.StyledSelectableRow_row__1y8hO {
  height: 100%;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  border: 0;
  border-bottom: solid 1px #b3b3b3;
  transition: 300ms background;
  background: #ffffff;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.StyledSelectableRow_row__1y8hO.StyledSelectableRow_selected__2mOZK {
  background: #e8e8e8;
}
.StyledSelectableRow_row__1y8hO:hover {
  background: #e5e5e5;
}
.StyledSelectableRow_row__1y8hO:hover .StyledSelectableRow_checkbox__3QGcJ:before {
  display: flex;
}
.StyledSelectableRow_main__VHaY6 {
  display: flex;
  width: 100%;
  align-items: center;
  min-width: 0;
  flex: 1 1;
}
.StyledSelectableRow_selectable__1gXAV {
  cursor: pointer;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.CommonDocumentRow_wrapper__3wf3Z {
  padding: 2px 0;
}
.CommonDocumentRow_row__3kqG2 {
  height: 100%;
}
.CommonDocumentRow_row__3kqG2 .CommonDocumentRow_thumbnail__2XVgK {
  width: 55px;
  align-self: stretch;
  margin-right: 1rem;
  flex: 0 0 auto;
  cursor: pointer;
}
.CommonDocumentRow_row__3kqG2 .CommonDocumentRow_thumbnail__2XVgK .anticon {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CommonDocumentRow_row__3kqG2 .CommonDocumentRow_thumbnail__2XVgK:hover {
  outline: solid 1px #193d71;
}
.CommonDocumentRow_row__3kqG2 .CommonDocumentRow_linkIcon__1RP3l {
  margin-right: 0.3rem;
}
.CommonDocumentRow_row__3kqG2 .CommonDocumentRow_activeLink__3eOuR {
  color: #193d71;
}
.CommonDocumentRow_row__3kqG2 .CommonDocumentRow_new__2AvZP .CommonDocumentRow_main__1nRmG {
  position: relative;
}
.CommonDocumentRow_row__3kqG2 .CommonDocumentRow_new__2AvZP .CommonDocumentRow_main__1nRmG .CommonDocumentRow_nameLabels__1DHS0 .CommonDocumentRow_name__qPUBH {
  font-weight: 900;
}
.CommonDocumentRow_row__3kqG2 .CommonDocumentRow_new__2AvZP .CommonDocumentRow_main__1nRmG:before {
  content: '+';
  font-size: 1.5em;
  font-weight: 700;
  color: #193d71;
  margin-right: 4px;
  position: absolute;
  top: -0.4em;
  left: -0.7em;
  line-height: 1em;
}

.DocumentRow\.Date_parameters__1elEN {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  line-height: 1.25em;
}

.DocumentRow\.Description_formatedTooltip__1wrZ8 {
  white-space: pre-line;
  min-width: 300px;
  max-width: 50%;
}
.DocumentRow\.Description_description__1JTgx {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

.DocumentRow\.Name_name__1r4pm {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 8px;
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
}
.DocumentRow\.Name_name__1r4pm a:hover {
  text-decoration: underline !important;
}

.DocumentRow\.Path_path__2Xdj0 {
  font-size: 12px;
  font-weight: 400;
}
.DocumentRow\.Path_path__2Xdj0 a:hover {
  text-decoration: underline;
}

.PathSelector_container__2vglj {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 1rem;
}
.PathSelector_pathWithoutLast__1P0Zy {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 32px;
}
.PathSelector_pathLastPart__JlRR1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PathSelector_arrow__wzujz {
  font-size: 0.7rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.PathSelector_root__2mRdq {
  margin-right: 0.4em;
}
.PathSelector_simpleLink__3LEZe {
  display: flex;
}
.PathSelector_part__1iRTo {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PathSelector_part__1iRTo:first-child {
  min-width: 0;
}
.PathSelector_part__1iRTo:last-child {
  flex: 1 1;
  min-width: 5rem;
}
.PathSelector_partLink__16UhB {
  overflow: hidden;
  text-overflow: ellipsis;
}

.EsticonObjectGridLink_gridLinkIcon__iC5IL {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LabelDisplay_link__3cJf1 {
  cursor: pointer;
}

.LabelsStack_labelsWrap__1sOdu {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: flex-start;
  margin-right: 0.5rem;
  min-width: 60px;
}
.LabelsStack_labels__1_Dfp {
  flex: 1 1;
  display: flex;
  overflow: hidden;
  grid-gap: 0.2rem 2px;
  gap: 0.2rem 2px;
}
.LabelsStack_overlay__1o42b {
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.2rem;
  overflow: hidden;
  padding: 0.5rem;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
}
.LabelsStack_overlayInner___rnIp {
  display: flex;
  flex-wrap: wrap;
  max-width: 200px;
}
.LabelsStack_modeTags__brydh {
  cursor: pointer;
}
.LabelsStack_label__3a_Q_ {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.LabelsStack_label__3a_Q_ > * {
  display: initial !important;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DocumentRow_documentName__2P_wM {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
}
.DocumentRow_documentName__2P_wM a:hover {
  text-decoration: underline;
}
.DocumentRow_main__GBok3 {
  flex: 1 1;
  overflow: hidden;
  min-width: 90px;
  display: flex;
  flex-direction: column;
}
.DocumentRow_main__GBok3 .DocumentRow_nameLabels__15L6m {
  display: flex;
}

.DocumentsGrid_list__2bvxJ {
  overflow: auto !important;
}
.DocumentsGrid_list__2bvxJ:focus {
  outline: none;
}
.DocumentsGrid_list__2bvxJ > * {
  overflow: unset !important;
}

.ShrinkableToolbar_menu__3gVED {
  flex: auto;
  min-width: 0;
  border-bottom: 1px solid transparent;
}
.ShrinkableToolbar_right__320kR {
  justify-content: flex-end;
}
.ant-menu-sub .ShrinkableToolbar_down__2C2nh {
  display: none;
}
.ant-menu-overflow-item.ant-menu-item.ant-menu-item,
.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-horizontal {
  padding-inline: 0.5rem;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  left: 0.5rem !important;
  right: 0.5rem !important;
}
@media (max-width: 900px) {
  .ShrinkableToolbar_menu__3gVED .ant-menu-title-content {
    display: none;
  }
  .ShrinkableToolbar_menu__3gVED .ant-menu-submenu {
    padding: 0 !important;
  }
  .ShrinkableToolbar_menu__3gVED .ant-menu-submenu .ant-menu-submenu-title {
    padding: 0 1rem !important;
  }
  .ShrinkableToolbar_menu__3gVED .ant-menu-overflow-item.ant-menu-item.ant-menu-item,
  .ShrinkableToolbar_menu__3gVED .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-horizontal {
    padding-inline: 1rem;
  }
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.PreviousNextButtons_content__2kYth {
  display: flex;
  white-space: nowrap;
  min-width: 4em;
  text-align: center;
  width: auto;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  cursor: pointer;
  border-radius: 0.25rem;
}
.PreviousNextButtons_content__2kYth:hover {
  outline: solid 3px #193d71;
}
.PreviousNextButtons_content__2kYth:has(.PreviousNextButtons_input__295yw) {
  outline: solid 3px #193d71;
}
.PreviousNextButtons_input__295yw {
  background: transparent;
  min-width: 1em;
  border: none;
  outline: none;
  padding: 0;
  max-width: 10em;
}

.Viewer_viewer__2UcAi {
  background-color: #f1f1f1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.Viewer_viewer__2UcAi > video {
  max-width: 100%;
  max-height: 100%;
}
.Viewer_viewer__2UcAi > audio {
  width: 100%;
}
.Viewer_viewer__2UcAi > .Viewer_beforeViewContent__QgIda {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}
.Viewer_viewer__2UcAi .Viewer_infoBox__SdP8e {
  max-width: 400px;
  margin-top: 1rem;
  text-align: center;
}

.MeasurementsResult_results__uVtbF {
  max-width: 300px;
}
.MeasurementsResult_title__3RMrt {
  font-weight: bold;
  margin-bottom: 0.5em;
  word-break: break-word;
}
.MeasurementsResult_tag__fHRrb {
  font-size: 1rem;
  font-weight: bold;
}

.Forge_centeredContent__2-zCL {
  flex: 1 1;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Forge_ForgeViewer__WolOs {
  /*flex: 1;*/
  height: 100%;
  width: 100%;
  position: relative;
}
.Forge_overlayContainer__2Wxnz {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  z-index: 1;
  display: flex;
  flex-direction: row;
  max-width: 90%;
  align-items: flex-start;
}
.Forge_overlayContainer__2Wxnz:empty {
  display: none;
}
.Forge_overlayContainer__2Wxnz .Forge_bubbleNodesSelect__2LBxr {
  max-width: 150px;
}
.Forge_loadbarOuter__Fs1wD {
  position: absolute;
  bottom: 10px;
  left: 4px;
  width: 242px;
  height: 6px;
  background-color: #2c2c2c;
  z-index: 1;
}
.Forge_loadbarMiddle__2qLBP {
  background-color: black;
  margin: 1px;
  height: 4px;
  width: 240px;
}
.Forge_loadbarInner__Qt35J {
  height: 100%;
  background-color: red;
  border-radius: 2px;
}
.Forge_ForgeViewer__WolOs .Forge_viewer__jcPQo {
  position: relative;
  width: 100%;
  height: 100%;
  /*top: 0;*/
  /*left: 0;*/
}
.Forge_ForgeViewer__WolOs .Forge_scrim__kJk-z {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #ededed;
  color: #95a5a6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Forge_ForgeViewer__WolOs .Forge_scrim__kJk-z .Forge_message__S_XEm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Forge_ForgeViewer__WolOs .Forge_scrim__kJk-z .Forge_message__S_XEm svg {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}
.Forge_errorMessageWrap__SY-vT {
  margin-bottom: 1rem;
}
h4.Forge_unitTitle__33Nt4 {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.Forge_notFoundElementsError__2fGJk {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  max-width: 100%;
  text-align: center;
}

h4.ForgeConsistencyWarning_unitTitle__nVvES {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.ForgeGroupedViewer_viewer__20wIL {
  background-color: #f1f1f1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ForgeGroupedViewer_viewer__20wIL > video {
  max-width: 100%;
  max-height: 100%;
}
.ForgeGroupedViewer_viewer__20wIL > audio {
  width: 100%;
}
.ForgeGroupedViewer_viewer__20wIL > .ForgeGroupedViewer_beforeViewContent__2_4Dj {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}
.ForgeGroupedViewer_viewer__20wIL .ForgeGroupedViewer_infoBox__3Iu4G {
  max-width: 400px;
  margin-top: 1rem;
  text-align: center;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.XmlViewer_container__22wWk {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.XmlViewer_scrollable__13hFA {
  overflow: auto;
  margin: 0;
  width: 100%;
  flex-grow: 1;
  border: solid 1px #e5e5e5;
  background: #e5e5e5;
  padding: 0.5rem;
}

.Processor_content__2ojwt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.Processor_scrollable__bc_mJ {
  overflow: auto;
  margin: 0;
  width: 100%;
  flex-grow: 1;
  padding: 1rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProcessedJsonViewer_messageContainer__WMoZK {
  padding: 2rem;
  overflow: hidden;
}
.ProcessedJsonViewer_headers__2_qqF tr > td {
  padding: 0.7rem 16px;
}

.FileViewerModal_modalBody__2I9Rc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.FileViewerModal_navButtons__CKkfd {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 48px;
  font-size: 32px;
  line-height: 0;
  margin: auto;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background: #dbdbdb;
  z-index: 1;
}
.FileViewerModal_navButtons__CKkfd > .anticon {
  margin: 8px;
}
.FileViewerModal_navButtons__CKkfd.FileViewerModal_prev__3Qnpr {
  left: 0;
}
.FileViewerModal_navButtons__CKkfd.FileViewerModal_next__MQu16 {
  right: 0;
}
.FileViewerModal_spinBox__2TIsg {
  flex: 1 1;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: none !important;
}
.FileViewerModal_modal__2N8Xi {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 1000;
}
.FileViewerModal_geneButton__FdSJ0 {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.FilterDisplay_filterDropdown__3S8-9 {
  display: flex;
  flex-direction: column;
  max-height: min(30rem, 100vh);
  overflow: auto;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.FilterDisplay_filterDropdownFooter__6Kmii {
  border-top: solid 1px #f1f1f1;
  display: flex;
}
.FilterDisplay_clearFilter__Y0uGP {
  text-align: right;
  margin-left: auto;
  justify-content: flex-end;
}
.FilterDisplay_filterButton__1pEl1 {
  display: flex;
  align-items: center;
  border-radius: 2rem;
  padding-inline: 0.75rem;
}
.FilterDisplay_filterButton__1pEl1.FilterDisplay_isSet__3zrJj {
  background-color: #193d71;
  border-color: transparent;
  color: #ffffff;
}
.FilterDisplay_filterButton__1pEl1.FilterDisplay_isOpen__3ZkTB {
  background-color: #fee7cc;
  border-color: transparent;
}
.FilterDisplay_filterButton__1pEl1 > i.anticon {
  margin-left: 0.3em;
}
.FilterDisplay_filterButton__1pEl1 .FilterDisplay_tag__3OrAh {
  border-radius: 0.25rem;
  background: #ffffff;
  color: #000000;
  font-weight: 600;
  padding: 0 0.4rem;
  font-size: 0.7rem;
  min-width: 1.2rem;
  text-align: center;
}
.FilterDisplay_filterValue__OqnpY {
  margin-left: 0.3em;
  font-weight: bold;
}
.FilterDisplay_icon__vcwFq {
  margin-left: 0.5em;
}

.DateFilter_title__3mFIf {
  padding: 6px 16px;
  font-weight: 700;
  max-width: 320px;
}
.DateFilter_typeSelect__3IsXn {
  padding: 0.5rem 1rem;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ExtensionFilter_filterText__13B3W {
  padding: 0.5rem;
}
.ExtensionFilter_filterText__13B3W > .ant-input {
  background: #f1f1f1 !important;
  border: none !important;
}
.ExtensionFilter_extensionLabel__FS5vS {
  max-width: 80px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ExtensionFilter_extensionOption__1gPq- {
  padding: 0.5rem;
}

.FilterLabel_label__D0PDT {
  max-width: 2.5em;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.SelectFilter_filterList__4en2H {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1;
}
.SelectFilter_filterList__4en2H .SelectFilter_filterOption__3lHrW {
  padding: 0.5rem 1rem;
  margin: 0;
  display: flex;
  align-items: center;
}
.SelectFilter_filterList__4en2H .SelectFilter_filterOption__3lHrW .ant-checkbox {
  top: 0;
}
.SelectFilter_selectCheckbox__1e31Z {
  padding: 0.5rem 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e5e5e5;
}
.SelectFilter_selectCheckbox__1e31Z .ant-checkbox {
  top: 0;
}
.SelectFilter_filterDropDownHeader__38y5p {
  border-bottom: solid 1px #f1f1f1;
  display: flex;
  flex-direction: column;
}
.SelectFilter_andOperator__3lAdr {
  padding: 0.5rem 1rem;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.SelectFilter_title__2MsTU {
  padding: 6px 16px;
  font-weight: 700;
  max-width: 320px;
}

.UsersFilter_typeSelect__kuKH3 {
  padding: 0.5rem 1rem;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.FiltersFormItem_wrapper__2nSqt {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
.FiltersFormItem_itemCounter__1hBaK {
  padding: 0.3rem 0.5rem;
}

.FilterPresetManageModal_listItem__3TeF6 {
  margin-bottom: 0.5rem;
}

.FilterPreset_layout__2IazX {
  flex-wrap: wrap;
}
.FilterPreset_select__vIZ-o {
  width: 20dvw;
  min-width: 150px;
  max-width: 250px;
}
@media (max-width: 600px) {
  .FilterPreset_select__vIZ-o {
    width: 100%;
  }
}

.FilterToolbar_filterToolbar__3slbN {
  border-radius: 0.25rem;
  align-items: center;
  max-width: 100%;
  display: flex;
  flex: 1 1;
  min-width: 180px;
  grid-gap: 0.25rem 0.5rem;
  gap: 0.25rem 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
}

.DocumentMultipleActionError_errorList__3AtM3 {
  margin-top: 0.5rem;
}
.DocumentMultipleActionError_errorLabel__3-eNv {
  margin-top: 0.5rem;
}

.DocumentsDownloadForm_downloadDocumentsWrap__Wo7RC {
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}
.DocumentsDownloadForm_downloadDocumentsTitle__3e5yj {
  display: block;
  margin-right: 0.5rem;
}

.PrimaryFileViewerTitle_title__3fSsJ {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  word-break: break-word;
}
.PrimaryFileViewerTitle_tag__vn84W {
  margin-left: 1rem;
  height: 22px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.Toolbar_toolbar__2xMlq {
  flex: 0 0 auto;
  min-height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.Toolbar_toolbar__2xMlq .ant-menu-horizontal {
  line-height: 39px;
}
.Toolbar_toolbar__2xMlq.Toolbar_padding__2-GEX {
  padding: 0 0.5rem;
}
.Toolbar_toolbar__2xMlq.Toolbar_styled__2Irf8 {
  background-color: #ffffff;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  border-bottom: 1px solid #e6e8f1;
}
.Toolbar_block__1Kmb- {
  flex: 1 1;
}
.Toolbar_docsToolbarLeft__2k4tl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 1 auto;
  min-width: 0;
  flex-wrap: wrap;
}
.Toolbar_center__f0M7Q {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1 1;
  max-width: 100%;
  min-width: 1rem;
}
.Toolbar_docsToolbarRight__OmMdg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 auto;
  min-width: 0;
}
.Toolbar_flex__Xmuva {
  flex: 1 0;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ShrinkablePath_toolbar__3oQNF {
  display: flex;
}
.ShrinkablePath_pathContainer__19pCb {
  min-width: 0;
  flex: 1 1;
}
.ShrinkablePath_item__3gOBM {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  white-space: nowrap;
  padding-left: 0.5rem;
  font-weight: 500;
}
.ShrinkablePath_item__3gOBM.ShrinkablePath_hasLink__28sXB {
  color: inherit;
}
.ShrinkablePath_item__3gOBM .ShrinkablePath_strong__3oqhx {
  font-weight: 700;
  cursor: pointer;
}
.ShrinkablePath_item__3gOBM.ShrinkablePath_overflow__3Bimn:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ShrinkablePath_item__3gOBM.ShrinkablePath_overflow__3Bimn:first-child .ShrinkablePath_label__1lunB {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ShrinkablePath_item__3gOBM .ShrinkablePath_link__3oNcO {
  color: #193d71;
  text-decoration: none;
  cursor: pointer;
}
.ShrinkablePath_item__3gOBM .ShrinkablePath_link__3oNcO:hover {
  text-decoration: underline;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DocumentsHeader_titlePanel__3UcgU {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  min-width: 0;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.DocumentsHeader_docsToolbarTitle__1PE0K {
  flex: 1 0;
  min-width: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.RadioFilter_filterList__3TqdA {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1;
}
.RadioFilter_filterList__3TqdA .RadioFilter_filterOption__3dZO_ {
  padding: 0.5rem 1rem;
  margin: 0;
}
.RadioFilter_filterDropDownHeader__11dqx {
  border-bottom: solid 1px #f1f1f1;
  display: flex;
  flex-direction: column;
}
.RadioFilter_searchInput__1S61r {
  padding: 0.5rem;
}
.RadioFilter_searchInput__1S61r > .ant-input {
  background: #f1f1f1 !important;
  border: none !important;
}
.RadioFilter_firstSelected__1XqPJ {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.RadioFilter_title__3vZmQ {
  padding: 6px 16px;
  font-weight: 700;
  max-width: 320px;
}

.DirectoryCreateLinkForm_item__ofPtY {
  margin-bottom: 0;
}
.DirectoryCreateLinkForm_movingDirectoryWrap__1DVMZ {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}
.DirectoryCreateLinkForm_movingDirectoryTitle__31vg5 {
  display: block;
  margin-right: 0.5rem;
}
.DirectoryCreateLinkForm_movingDirectoryPath__k6heT {
  flex: 1 1;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.DirectoryLinkDiscard_discardDirectoryLinkWrap__MZ1fj {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}
.DirectoryLinkDiscard_discardDirectoryLinkTitle__3GzxU {
  display: block;
  margin-right: 0.5rem;
}
.DirectoryLinkDiscard_discardDirectoryLinkPath__2Go6V {
  flex: 1 1;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.DirectoryLinkMoveForm_item__2PCOm {
  margin-bottom: 0;
}
.DirectoryLinkMoveForm_movingDirectoryWrap__32_Iw {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}
.DirectoryLinkMoveForm_movingDirectoryTitle__uxhC4 {
  display: block;
  margin-right: 0.5rem;
}
.DirectoryLinkMoveForm_movingDirectoryPath__2r2X2 {
  flex: 1 1;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.DirectoryMoveForm_item__3Oxmx {
  margin-bottom: 0;
}
.DirectoryMoveForm_movingDirectoryWrap__1wlCY {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}
.DirectoryMoveForm_movingDirectoryTitle__20naX {
  display: block;
  margin-right: 0.5rem;
}
.DirectoryMoveForm_movingDirectoryPath__3QuAa {
  flex: 1 1;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.AccessLevelList_row__ZNAUO {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.AccessLevelList_rowHighlighted__206gJ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  padding-left: 0.3rem;
  margin-left: -0.3rem;
  padding-right: 0.3rem;
  margin-right: -0.3rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: 0.2rem;
}
.AccessLevelList_label__1g3az {
  flex: 1 0;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
}
.AccessLevelList_delete__3Fyz6 {
  width: 24px;
  text-align: center;
}
.AccessLevelList_labelButtonWrap__2Kv5y {
  display: flex;
  flex: 1 1;
  align-items: center;
  flex-wrap: nowrap;
}
.AccessLevelList_rowContainer__Hlh9k {
  background-color: white;
  margin-block: 0.5rem;
  padding: 0.5rem 0.3rem 0.1rem 0.3rem;
  border-radius: 0.2rem;
}
.AccessLevelList_rowContainer__Hlh9k:hover {
  background-color: #b3b3b3;
}

.DirectorySettingsPermissionsForm_inheritanceSwitch__1RkQi {
  margin-block: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.DirectorySettingsPermissionsForm_centerButton__BShZt {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DirectorySettingsPermissions_modalWindow__s-tki {
  position: relative;
  background-color: rgba(255, 255, 255);
  flex: 1 1;
  overflow: auto;
}
.DirectorySettingsPermissions_settingsSection__ZEwE9 {
  padding: 0 0.8rem;
  margin: 0 0 1rem 0;
  flex: 1 1;
}
.DirectorySettingsPermissions_buttonGroup__1nC1e {
  justify-content: flex-end;
  display: flex;
  background-color: #d8d8d8;
  padding: 0.5rem 1rem;
}
.DirectorySettingsPermissions_modalWrap__1hhHp {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  z-index: 120;
  position: relative;
  padding: 0;
}
.DirectorySettingsPermissions_emptyWrap__TOvFa {
  display: flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.GeneralSettingsItemsGroup_container__3HB-e {
  display: grid;
  border-top: solid 1px #e8e8e8;
}
.GeneralSettingsItemsGroup_container__3HB-e .GeneralSettingsItemsGroup_items__xpX0z {
  display: flex;
  flex-direction: column;
  grid-gap: 0;
  gap: 0;
}
.GeneralSettingsItemsGroup_container__3HB-e .GeneralSettingsItemsGroup_items__xpX0z > * + * {
  border-top: solid 1px #e8e8e8;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.SwitchLabeled_wrapper__1GddX {
  display: flex;
}
.SwitchLabeled_container__1ilox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.SwitchLabeled_container__1ilox.SwitchLabeled_labelOnLeft__3637t {
  flex-direction: row-reverse;
}
.SwitchLabeled_container__1ilox .ant-switch-checked {
  background-color: #193d71;
}
.SwitchLabeled_container__1ilox.SwitchLabeled_disabled__ygkEY .ant-switch-checked {
  background-color: #666666;
}
.SwitchLabeled_label__2khUB {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  cursor: pointer;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.CategoryListItem_categoryFilterUsedItem__3bsgs {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background: #e5e5e5;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  flex-direction: column;
  max-width: 300px;
}
.CategoryListItem_categoryFilterUsedItem__3bsgs .CategoryListItem_nodeItem__1bxEa {
  border-radius: 0.5rem;
  background: #ffffff;
  padding: 0 0.5rem;
  font-weight: normal;
  max-width: 100%;
  font-style: italic;
}
.CategoryListItem_categoryFilterUsedItem__3bsgs .CategoryListItem_categoryItem__1HKzE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  min-width: 0;
  max-width: 100%;
}

.DocumentCategoryForm_addButton__31o53 {
  margin: 0.2rem auto 0.5rem;
}
.DocumentCategoryForm_itemRow__2thyD {
  margin-bottom: 0;
}
.DocumentCategoryForm_treeSelectInput__2k_uN {
  display: block;
}

.SettingsItem_container__qfzv7 {
  display: flex;
  flex-direction: column;
  tab-index: 0;
}
.SettingsItem_headline__2wqPf {
  font-weight: 700;
}
.SettingsItem_title__2cQ1t {
  font-size: 16px;
  font-weight: 600;
  min-height: 2em;
  display: flex;
  align-items: center;
}
.SettingsItem_title__2cQ1t > .anticon {
  margin-left: 8px;
}
.SettingsItem_spacer__3C2op {
  flex: 1 1;
}
.SettingsItem_errorMessage__12Lsj {
  margin-bottom: 8px;
  margin-top: 8px;
}
.SettingsItem_content__2B2k_ {
  display: flex;
  flex-direction: column;
  tab-index: 0;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.MaskTag_tag__8xxG6 {
  min-height: 2rem;
  padding: 0.2rem;
  display: flex;
  flex: 1 1;
  align-items: center;
  overflow: hidden;
  margin: 0;
  grid-gap: 0.2rem 0.5rem;
  gap: 0.2rem 0.5rem;
  flex-wrap: wrap;
}

.Draggable_draggable__1xosI {
  min-width: 0;
  width: -webkit-min-content;
  width: min-content;
}
.Draggable_draggable__1xosI.Draggable_dragging__2NO0r {
  opacity: 0.5;
}

.DropIndicator_dropIndicator__2-Rnc {
  --height: 3px;
  display: block;
  height: var(--height);
  margin-bottom: calc(var(--height) * -1);
  width: 100%;
  outline: none;
  background: transparent;
  align-self: stretch;
  border-radius: 0.2rem;
}
.DropIndicator_dropIndicator__2-Rnc:last-child {
  margin-bottom: 0;
  margin-top: calc(var(--height) * -1);
}
.DropIndicator_dropIndicator__2-Rnc.DropIndicator_dropTarget__3tyOA {
  background: blue;
  position: relative;
  --radius: 6px;
}
.DropIndicator_dropIndicator__2-Rnc.DropIndicator_dropTarget__3tyOA:before {
  content: '';
  position: absolute;
  top: calc(var(--radius) * -1 + 1.5px);
  left: calc(var(--radius) * -2 + 6px);
  border-radius: var(--radius);
  height: calc(var(--radius) * 2);
  width: calc(var(--radius) * 2);
  display: block;
  background: white;
  border: solid 3px blue;
}
.DropIndicator_dropIndicator__2-Rnc.DropIndicator_dropTarget__3tyOA:after {
  content: '';
  position: absolute;
  top: calc(var(--radius) * -1 + 1.5px);
  right: calc(var(--radius) * -2 + 6px);
  border-radius: var(--radius);
  height: calc(var(--radius) * 2);
  width: calc(var(--radius) * 2);
  display: block;
  background: white;
  border: solid 3px blue;
}

.MaskReorderableList_maskReorderable__edBF6[role=listbox] {
  padding: 0.6rem;
  list-style: none;
  outline: none;
  min-height: 2rem;
  overflow: visible;
  border-radius: 0.2rem;
  background: #eaeaea;
}
.MaskReorderableList_maskReorderable__edBF6[role=listbox]:empty {
  box-sizing: border-box;
  border: 2px dashed gray;
  box-shadow: none;
  min-height: 2rem;
}
.MaskReorderableList_option__pIAnI {
  outline: none;
}
.MaskReorderableList_option__pIAnI.MaskReorderableList_focusVisible__3G-jj {
  box-shadow: inset 0 0 0 2px orange;
}
.MaskReorderableList_option__pIAnI.MaskReorderableList_dropTarget__3MZPO {
  border-color: transparent;
  box-shadow: inset 0 0 0 2px var(red);
}

.MaskInput_toolbar__1HHMR {
  margin-bottom: 0.5rem;
  padding: 0.4rem 0.5rem;
  border-radius: 0.25rem;
  background: #eaeaea;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.TextInputSettingsItem_textarea__3p2LA {
  width: 100%;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.FlexTabs_flexTabs__2ot95 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 0;
}
.FlexTabs_flexTabs__2ot95 > .ant-tabs-nav {
  margin-bottom: 0;
}
.FlexTabs_flexTabs__2ot95 > .ant-tabs-nav::before {
  border-color: #e5e5e5;
}
.FlexTabs_flexTabs__2ot95 > .ant-tabs-nav .ant-tabs-tab {
  margin-left: 1.5em;
}
.FlexTabs_flexTabs__2ot95 > .ant-tabs-content-holder {
  display: flex;
}
.FlexTabs_flexTabs__2ot95 > .ant-tabs-content-holder > .ant-tabs-content {
  display: flex;
  flex: 1 1;
}
.FlexTabs_flexTabs__2ot95 > .ant-tabs-content-holder > .ant-tabs-content > * {
  max-width: 100%;
  flex: 1 1;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DirectorySettingsForm_container__28cXR {
  background: #ffffff;
  border-radius: 0.25rem;
}
.DirectorySettingsForm_metadataTab__1rTgq {
  background-color: #f1f1f1;
}

.CommonDocumentsGrid_list__102Zn {
  overflow: auto !important;
}
.CommonDocumentsGrid_list__102Zn:focus {
  outline: none;
}
.CommonDocumentsGrid_list__102Zn > * {
  overflow: unset !important;
}
.CommonDocumentsGrid_wrapper__TY-Le {
  flex: 1 1;
  height: 100%;
  margin-top: 0.5rem;
}

.JSONVariableConditionsFormItem_wideSelect__1QULi {
  width: 100%;
}
.JSONVariableConditionsFormItem_deleteButton__3g3jK {
  padding: 1.8rem 0 0;
}
.JSONVariableConditionsFormItem_conditionLabel__3p76J > .ant-form-item-row {
  flex-flow: column;
}

@media (max-width: 560px) {
  .JSONVariableDateRangePickerFormItem_responsiveCalendar__1cJ0W .ant-picker-panels {
    width: 320px;
    flex-direction: column;
  }
  .JSONVariableDateRangePickerFormItem_responsiveCalendar__1cJ0W .ant-calendar-range-part {
    width: 100%;
  }
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DirectoriesTree_tree__2raDm {
}
.DirectoriesTree_tree__2raDm .ant-tree-draggable-icon {
  opacity: 1 !important;
  display: none;
}
.DirectoriesTree_tree__2raDm .ant-tree-treenode::before {
  display: none;
}
.DirectoriesTree_tree__2raDm .ant-tree-treenode-selected > * {
  background-color: #193d71 !important;
  color: #fff;
}
.DirectoriesTree_tree__2raDm .ant-tree-treenode-selected > *:last-child {
  padding-right: 0.5rem;
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.DirectoriesTree_tree__2raDm .ant-tree-treenode-selected > *:first-child {
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.DirectoriesTree_tree__2raDm .ant-tree-list-holder {
  height: var(--height);
}
.DirectoriesTree_tree__2raDm .ant-tree-treenode:nth-child(2n) {
  background: linear-gradient(90deg, #f5f5f5 50%, rgba(240, 240, 240, 0) 100%);
}
.DirectoriesTree_tree__2raDm .ant-tree-indent-unit {
  width: 0.75rem;
}
.DirectoriesTree_dropdown__yVNzh {
  display: inline-block;
  width: calc(100% - 30px);
}
.DirectoriesTree_title__3Dl7R {
  display: flex;
  align-items: center;
  min-height: 24px;
  font-weight: 600;
  min-width: 50px;
}
.DirectoriesTree_titleLeft__35zur {
  flex: 1 1;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: inherit;
  min-height: 24px;
  line-height: 24px;
  white-space: nowrap;
}
.DirectoriesTree_linkTooltipSmall__1AXDC .ant-tooltip-inner {
  width: 300px;
}
.DirectoriesTree_linkTooltipMedium__I2X7M .ant-tooltip-inner {
  width: 500px;
}
.DirectoriesTree_linkTooltipLarge__2v_n3 .ant-tooltip-inner {
  width: 700px;
}

.JSONVariableProjectDirectorySelectModal_directoryListWrapper__jdXiA {
  max-height: 70vh;
  height: 50vh;
  width: 400px;
}
.JSONVariableProjectDirectorySelectModal_directroryContentWrapper__Jp00o {
  width: 100%;
  margin-left: 1rem;
}
.JSONVariableProjectDirectorySelectModal_responsiveTitle__1MNbk {
  display: none;
}
.JSONVariableProjectDirectorySelectModal_documentSelectContainer__3WVOx {
  width: 100%;
  height: 100%;
}
@media (max-width: 600px) {
  .JSONVariableProjectDirectorySelectModal_documentSelectContainer__3WVOx {
    flex-direction: column;
  }
  .JSONVariableProjectDirectorySelectModal_documentSelectContainer__3WVOx .JSONVariableProjectDirectorySelectModal_responsiveTitle__1MNbk {
    display: block;
    margin-bottom: 0.5rem;
  }
  .JSONVariableProjectDirectorySelectModal_documentSelectContainer__3WVOx .JSONVariableProjectDirectorySelectModal_directroryContentWrapper__Jp00o {
    width: 100%;
    height: 40vh;
    margin-top: 1rem;
    margin-left: 0;
    background-color: #fefefe;
  }
  .JSONVariableProjectDirectorySelectModal_documentSelectContainer__3WVOx .JSONVariableProjectDirectorySelectModal_directoryListWrapper__jdXiA {
    height: 40vh;
    width: 100%;
  }
}

.RestoreForm_restoreDocumentsWrap__yqojk {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}
.RestoreForm_restoreDocumentsTitle__FC7tk {
  display: block;
  margin-right: 0.5rem;
}

.JSONVariableProjectLinkSelectorFormItem_linkLabel__1N4oq {
  margin-right: 1rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DropArea_wrapper__QUDwG {
  display: inherit;
  flex-direction: inherit;
  flex: 1 1;
  overflow: hidden;
}
.DropArea_dropArea__wrLFh {
  z-index: 111;
  border: dashed 4px #193d71;
  background: #ffffffdb;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DropArea_dropArea__wrLFh .DropArea_hint__yW9ZI {
  text-align: center;
  margin: auto;
  font-size: 1.5rem;
}
.DropArea_dropArea__wrLFh.DropArea_hidden__1wfN9 {
  visibility: hidden;
}
.DropArea_dropArea__wrLFh.DropArea_disabled__1gUzV {
  cursor: no-drop;
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
}
.DropArea_input__2lFkL {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Resizer_resizer__1Z9sh {
  --gap: 0.5rem;
  --margin: 0.25rem;
  width: calc(var(--gap) + (var(--margin) * 2));
  margin-inline: calc(-0.5rem - (var(--margin)));
  z-index: 100;
  border-left: solid 1px transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: ew-resize;
  height: 100%;
  right: calc(var(--margin) * -1);
  top: 0;
  user-select: none;
  -webkit-user-select: none;
  background: transparent;
  transition: background 0.5s ease;
  overflow: hidden;
  color: #cccccc;
  font-size: 2rem;
}
.Resizer_resizer__1Z9sh:after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  z-index: 1;
}
.Resizer_resizer__1Z9sh:hover:after {
  background-color: rgba(0, 0, 0, 0.1);
}

.HeaderContentLayout_container__2djs0 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  min-height: 0;
}
.HeaderContentLayout_contentWrapper__K7jDL {
  flex: auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.HeaderContentLayout_content__2DVfH {
  display: flex;
  flex: 1 1;
  overflow: hidden;
  min-height: 0;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.AllDocumentsPage_directoryTree__zwELb {
  border-radius: 0.25rem;
  background: #ffffff;
}
.AllDocumentsPage_dropzone__23ZN- {
  flex: auto;
  position: relative;
  overflow: hidden;
  display: flex;
}
.AllDocumentsPage_docsPane__YC3dp {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
  height: 100%;
}
.AllDocumentsPage_dirsSection__23TP1 {
  overflow: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.AllDocumentsPage_content__12rXO {
  padding: 0;
  flex: auto;
  display: flex;
  flex-direction: row-reverse;
  min-height: 0;
}
.AllDocumentsPage_fileToolbar__2lr66 {
  background-color: #fff;
  padding: 0 0.5rem;
  margin: 0;
  border-radius: 0.25rem;
}
.AllDocumentsPage_directoryToolbar__2NLBk {
  background-color: #fff;
  padding: 0 0.5rem;
  margin: 0;
  border-radius: 0.25rem;
}
.AllDocumentsPage_documentGridWrap__3esg3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1;
}
.AllDocumentsPage_subMenu__16VLJ {
  margin-right: 0.5rem;
}
.AllDocumentsPage_label__z35d5 {
  margin: 0 1rem 0.5rem 1rem;
  font-weight: 600;
}
.AllDocumentsPage_dirElipsis__QS4hG {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.BudgetGrid_accordingToSwitch__3j6k2 {
  margin-left: 0.25rem;
}
.BudgetGrid_tableWrap__2zqBL {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
}
.BudgetGrid_costColumn__6kgF0 {
  text-align: right;
  overflow: visible;
  position: relative;
}
.BudgetGrid_percentProgressWrap__3Tonq {
  position: absolute;
  left: -2px;
  right: -2px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.BudgetGrid_percentProgressWrap__3Tonq .BudgetGrid_percentProgress__3Wur8 {
  background: rgba(136, 219, 27, 0.65);
  height: 100%;
  flex: 1 1;
  width: 0;
  max-height: 1.5em;
}
.BudgetGrid_percentValue__1GudR {
  position: relative;
  z-index: 1;
}
.BudgetGrid_revisionTag__3l74i {
  display: inline-block;
  font-size: 0.7rem;
  height: 18px;
}
.BudgetGrid_table__2Vdq4 {
  font-weight: 500;
}
.BudgetGrid_table__2Vdq4 .dx-texteditor-input-container {
  height: 30px;
  line-height: 15px;
  display: flex;
}
.BudgetGrid_table__2Vdq4 .dx-button {
  height: 32px;
}
.BudgetGrid_table__2Vdq4 .dx-button .dx-button-content {
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.BudgetGrid_table__2Vdq4 .dx-treelist-header-panel {
  border: 0;
  height: 40px;
}
.BudgetGrid_table__2Vdq4 .dx-treelist-header-panel .dx-toolbar {
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1rem;
}
.BudgetGrid_table__2Vdq4 .dx-selection {
  outline-offset: -1px;
}
.BudgetGrid_table__2Vdq4 .dx-selection td {
  color: inherit !important;
}
.BudgetGrid_table__2Vdq4 .dx-row-focused td {
  color: inherit !important;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel0__2nlwF,
.BudgetGrid_table__2Vdq4 .BudgetGrid_Projekt__1hPT_ {
  background: #2458a3;
  color: #ffffff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel0__2nlwF td,
.BudgetGrid_table__2Vdq4 .BudgetGrid_Projekt__1hPT_ td {
  color: inherit !important;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel0__2nlwF.dx-treelist-empty-space,
.BudgetGrid_table__2Vdq4 .BudgetGrid_Projekt__1hPT_.dx-treelist-empty-space {
  color: inherit !important;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel1__GNoku,
.BudgetGrid_table__2Vdq4 .BudgetGrid_StavebniObjekt__QBZ4Y {
  background: #ffffff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel1__GNoku:nth-of-type(2n + 1),
.BudgetGrid_table__2Vdq4 .BudgetGrid_StavebniObjekt__QBZ4Y:nth-of-type(2n + 1) {
  background-color: #f9f9f9;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel2__25e4J,
.BudgetGrid_table__2Vdq4 .BudgetGrid_KonstrukcniPrvek__3c3vp,
.BudgetGrid_table__2Vdq4 .BudgetGrid_StavebniDil___gXBq {
  background: #e3f4ff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel2__25e4J:nth-of-type(2n + 1),
.BudgetGrid_table__2Vdq4 .BudgetGrid_KonstrukcniPrvek__3c3vp:nth-of-type(2n + 1),
.BudgetGrid_table__2Vdq4 .BudgetGrid_StavebniDil___gXBq:nth-of-type(2n + 1) {
  background-color: #d9f0ff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel3__1gHez,
.BudgetGrid_table__2Vdq4 .BudgetGrid_Element__3EGv4,
.BudgetGrid_table__2Vdq4 .BudgetGrid_Polozka__3V7l-,
.BudgetGrid_table__2Vdq4 .BudgetGrid_ZjistovaciProtokol__L77kO,
.BudgetGrid_table__2Vdq4 .BudgetGrid_PolozkaRozpis__3s5zx {
  background: #c0deff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel3__1gHez:nth-of-type(2n + 1),
.BudgetGrid_table__2Vdq4 .BudgetGrid_Element__3EGv4:nth-of-type(2n + 1),
.BudgetGrid_table__2Vdq4 .BudgetGrid_Polozka__3V7l-:nth-of-type(2n + 1),
.BudgetGrid_table__2Vdq4 .BudgetGrid_ZjistovaciProtokol__L77kO:nth-of-type(2n + 1),
.BudgetGrid_table__2Vdq4 .BudgetGrid_PolozkaRozpis__3s5zx:nth-of-type(2n + 1) {
  background-color: #b6d9ff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel4__2Tvf1 {
  background: #8dc1ff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_GridLevel4__2Tvf1:nth-of-type(2n + 1) {
  background-color: #83bbff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_MdRows__3FYA0 {
  background: #ffffff;
}
.BudgetGrid_table__2Vdq4 .BudgetGrid_MdRows__3FYA0:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}
.BudgetGrid_table__2Vdq4 td {
  background-color: inherit !important;
}
.BudgetGrid_table__2Vdq4 .dx-row-focused td {
  background-color: rgba(55, 255, 0, 0.44) !important;
}
.dx-treelist .dx-row > td {
  padding: 5px 8px;
}
.BudgetGrid_budgetWrap__lO-U5 {
  overflow: visible !important;
}
.BudgetGrid_thumbnail__23V1E {
  width: 45px;
  height: 30px;
  margin: -4px -7px;
  align-self: stretch;
  margin-right: 1rem;
  flex: 0 0 auto;
  cursor: pointer;
}
.BudgetGrid_thumbnail__23V1E .anticon {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BudgetGrid_thumbnail__23V1E:hover {
  outline: solid 1px #193d71;
}
.BudgetGrid_withMinHeight__ADfcl {
  min-height: 250px;
}
.BudgetGrid_overdrawned__2LttE {
  background-color: #ff4f5e;
}
.BudgetGrid_overdrawned__2LttE:nth-of-type(2n + 1) {
  background-color: #ff2b3d;
}

.BulkEditDocumentsStateForm_stateOption__JjPlM {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.DocumentLinksCreateForm_item__1tCSJ {
  margin-bottom: 0;
}
.DocumentLinksCreateForm_documentLinkWrap__2_pr7 {
  display: flex;
  flex-direction: row;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}
.DocumentLinksCreateForm_movingDocumentsTitle__2Tnu- {
  display: block;
  margin-right: 0.5rem;
}
.DocumentLinksCreateForm_sourceDocumentsPath__VbFfl {
  flex: 1 1;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.DocumentsMoveForm_item__3YCAh {
  margin-bottom: 0;
}
.DocumentsMoveForm_movingDocumentsWrap__29FlS {
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-weight: 500;
}
.DocumentsMoveForm_movingDocumentsTitle__1Llm0 {
  display: block;
  margin-right: 0.5rem;
}
.DocumentsMoveForm_movingDocumentsPath__814kz {
  flex: 1 1;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.DocumentsMoveRenameInfo_messageList__2eVGE {
  margin-top: 1rem;
}

.DocumentSelect_dirsSection__1xhSN {
  overflow: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.DocumentSelect_spinBoxIndicator__3TEWI {
  width: 300px;
}
.DocumentSelect_errorList__1NVVE {
  margin-top: 0.5rem;
}
.DocumentSelect_errorList__1NVVE p {
  margin-bottom: 0;
}
.DocumentSelect_errorLabel__2NAZ6 {
  margin-top: 0.5rem;
}
.DocumentSelect_wrapperDiv__3uQk0 {
  flex-grow: 1;
  width: 100%;
}

.WindowDisabler_mask__jF4aK {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.45);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  right: 0;
  z-index: 99;
}

.DocumentOnlineEditorRevisionCreateForm_stateOption__VLJbk {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.DocumentOnlineEditorModal_modalBody__3dis4 {
  height: 100%;
  width: 100%;
}
.DocumentOnlineEditorModal_almostHideContent__iROjO {
  max-height: 10px;
}
.DocumentOnlineEditorModal_modalBody__3dis4 iframe {
  height: 100%;
  width: 100%;
}
.DocumentOnlineEditorModal_modalError__3gc1C {
  text-align: center;
  display: block;
  padding: 1rem;
}
.DocumentOnlineEditorModal_bussySpinnerBox__NZM_M {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  width: 100px;
}

.VerticalSplitPane_splitPane__29qvi {
  position: relative !important;
  height: 100% !important;
}
.VerticalSplitPane_splitPane__29qvi .Pane {
  min-width: 0;
}
.VerticalSplitPane_splitPaneMargin__3lHs7 {
  padding: 1rem;
}
.VerticalSplitPane_resizer__3XBMj {
  z-index: 10 !important;
  position: relative;
}
.VerticalSplitPane_container__QlbVG {
  width: 100%;
  height: 100%;
}
.VerticalSplitPane_scrollable__2rfjX {
  height: auto;
}
.VerticalSplitPane_scrollablePane1__1NcgE > .Pane.Pane1 {
  overflow-y: auto;
}
.VerticalSplitPane_scrollablePane1__1NcgE > .Pane.Pane1::-webkit-scrollbar {
  width: 16px;
}
.VerticalSplitPane_scrollablePane1__1NcgE > .Pane.Pane1::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.VerticalSplitPane_scrollablePane1__1NcgE > .Pane.Pane1::-webkit-scrollbar-thumb {
  background-color: #193d71;
  background-clip: padding-box;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}
.VerticalSplitPane_scrollablePane1__1NcgE > .Pane.Pane1::-webkit-scrollbar-thumb:hover {
  background-color: #193d71;
}
.VerticalSplitPane_scrollablePane1__1NcgE > .Pane.Pane1::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.VerticalSplitPane_scrollablePane1__1NcgE > .Pane.Pane1::-webkit-scrollbar-corner {
  background-color: transparent;
}
.VerticalSplitPane_scrollablePane2__32LCs > .Pane.Pane2 {
  overflow-y: auto;
}
.VerticalSplitPane_scrollablePane2__32LCs > .Pane.Pane2::-webkit-scrollbar {
  width: 16px;
}
.VerticalSplitPane_scrollablePane2__32LCs > .Pane.Pane2::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.VerticalSplitPane_scrollablePane2__32LCs > .Pane.Pane2::-webkit-scrollbar-thumb {
  background-color: #193d71;
  background-clip: padding-box;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}
.VerticalSplitPane_scrollablePane2__32LCs > .Pane.Pane2::-webkit-scrollbar-thumb:hover {
  background-color: #193d71;
}
.VerticalSplitPane_scrollablePane2__32LCs > .Pane.Pane2::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.VerticalSplitPane_scrollablePane2__32LCs > .Pane.Pane2::-webkit-scrollbar-corner {
  background-color: transparent;
}
.VerticalSplitPane_hidePanel2__3ZxEB .Pane.Pane2 {
  display: none;
}
.VerticalSplitPane_hidePanel2__3ZxEB .Pane.Pane1 {
  width: 100% !important;
}

.RevisionCreateForm_stateOption__3RXGI {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}
.RevisionCreateForm_secondaryPanelIcon__3TJhL {
  margin-right: 4px;
}
.RevisionCreateForm_primaryFileError__KGKum {
  text-align: 'center';
  color: 'red';
  margin-top: 6;
}

.PdfAnnotation_workspace__niPLa {
  height: 100%;
}

.PdfSignature_workspace__16WkW {
  height: 100%;
}

.PdfSignatureModal_consendArea__3ltp9 {
  height: 100%;
  justify-content: center;
}
.PdfSignatureModal_wrapper__k_VLp {
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
}

.AddedRevisions_documentList__tmwR3 {
  margin-top: 0.5rem;
  max-height: 450px;
  height: 100%;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.WorkflowApprovalDocumentActivityList_activityRow__2udTG {
  border-radius: 0.25rem;
  background-color: #ffffff;
  transition: background-color 0.3s;
  padding: 0.5rem;
  margin-bottom: 0.2rem;
}
.WorkflowApprovalDocumentActivityList_activityRow__2udTG:hover {
  background-color: #f5f5f5;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ApprovalDocumentRevisionInfo_documentRow__vp2cC {
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  background: #ffffff;
  border-bottom: 1px solid #b3b3b3;
}
.ApprovalDocumentRevisionInfo_wrap__32PZe {
  flex-wrap: wrap;
}

.CalendarDaySelect_radioOption__1Hjcv {
  margin: 0.25rem 0;
  display: block;
}

.DateSelect_radioOption__2VSZZ {
  margin: 0.25rem 0;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MultipleRolesListSelect_managerForm__20uLS {
  display: flex;
}
.MultipleRolesListSelect_managerForm__20uLS .ant-select-selection.ant-select-selection--single {
  height: 48px !important;
}
.MultipleRolesListSelect_managerForm__20uLS .ant-select-selection.ant-select-selection--single > div {
  height: 100%;
  display: flex;
  align-items: center;
}
.MultipleRolesListSelect_managerItem__3N1x9 {
  padding: 0 0.5rem 0 0;
  border: solid 1px #e5e5e5;
  border-radius: 0.2rem;
  background: #ffffff;
}
.MultipleRolesListSelect_managerItem__3N1x9:not(:last-child) {
  margin-bottom: 0.5rem;
}
.MultipleRolesListSelect_managerItem__3N1x9:hover {
  background: #f1f1f1;
}
.MultipleRolesListSelect_roleSelect__33G1j {
  margin-bottom: 1rem;
}

.GrantingAccessModal_headerWithLink__HyjUn {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.GrantingAccessModal_section__2qxof {
  margin-bottom: 2rem;
}
.GrantingAccessModal_section__2qxof:last-child {
  margin-bottom: 0;
}
.GrantingAccessModal_path__tP4SK {
  font-weight: bold;
}
.GrantingAccessModal_message__gzOv- {
  margin-bottom: 1rem;
}
.GrantingAccessModal_userInfoSection__1kT-a {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.GrantingAccessModal_userInfoSection__1kT-a > * {
  flex: 1 1;
}
.GrantingAccessModal_infoItem__2-oO0 {
  margin-bottom: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
}
.GrantingAccessModal_infoValue__36bOm {
  font-size: 1rem;
}
.GrantingAccessModal_linkToSettings__KhVCG {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.GrantingAccessModal_highlight__1_sCY {
  background: #fff7d7;
  font-weight: 700;
}
.GrantingAccessModal_highlightExample__1KriP {
  padding: 0.1rem;
  margin: -0.1rem 0;
  display: inline-block;
}

.DocumentCreateForm_stateOption__1Mlkx {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}
.DocumentCreateForm_secondaryFileIcon__f1oGQ {
  margin-right: 4px;
}
.DocumentCreateForm_documentCreateFormModal__2-hiM {
  min-width: 320px;
  width: 1200px !important;
  max-width: calc(100% - 32px);
}
.DocumentCreateForm_wrapper__2eUSw {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
.DocumentCreateForm_mainSection__1h_1W {
  flex: 1 1;
  min-width: 280px;
  max-width: 100%;
}
.DocumentCreateForm_sideSection__2Wlog {
  flex: 0 0 320px;
  max-width: 100%;
}
@media (max-width: 900px) {
  .DocumentCreateForm_wrapper__2eUSw {
    flex-direction: column;
  }
  .DocumentCreateForm_sideSection__2Wlog {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .DocumentCreateForm_sideSection__2Wlog > * {
    flex: 1 1;
    min-width: 300px;
  }
}

.Capture_preview__Z-9PY {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
  flex: 1 1;
  min-height: 0;
}
.Capture_container__32uGu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  position: relative;
}
.Capture_recordButtons__2gVxu {
  position: absolute;
  bottom: 0.5rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem;
  min-height: 3rem;
}
.Capture_recordButtons__2gVxu .Capture_left__1oKPy {
  margin-right: auto;
}
.Capture_recordButtons__2gVxu .Capture_right__26SSA {
  margin-left: auto;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.Capture_recordButtons__2gVxu > * {
  flex: 1 1;
}
.Capture_recordButtonWrap__3h0gV {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Capture_recordButton__1RBpU {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  margin: 0;
  padding: 0;
  background: red;
  color: white;
  border: solid 1rem white;
  transition: border-width 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  z-index: 1;
  outline: solid 0.3rem rgba(0, 0, 0, 0.3) !important;
  justify-content: space-between;
}
.Capture_recordButton__1RBpU.Capture_recording__3ddGv {
  border-width: 0.2rem;
}
.Capture_recordButton__1RBpU.Capture_recording__3ddGv:after {
  z-index: 2;
  content: "";
  position: absolute;
  inset: 0.8rem;
  background: white;
}
.Capture_recordedVideoPreview__251JT {
  max-width: 80px;
  max-height: 3rem;
  display: block;
  flex: 1 1;
  outline: solid 0.3rem rgba(0, 0, 0, 0.3) !important;
  border-radius: 0.5rem;
}
.Capture_recordedVideoFull__2Eo9w {
  max-width: 100%;
  max-height: 100%;
  display: block;
  flex: 1 1;
}
.Capture_hidden__3qmgi {
  display: none;
}
.Capture_videoResult__3VQpT {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  inset: 0;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.CommonFilesInput_dropArea__mVRRv {
  margin-bottom: 1rem;
}
.CommonFilesInput_container__V_mDM {
  overflow: auto;
  max-height: 260px;
  padding-bottom: 3px;
}
.CommonFilesInput_container__V_mDM .ant-tree-node-content-wrapper,
.CommonFilesInput_container__V_mDM .ant-tree-node-content-wrapper,
.CommonFilesInput_container__V_mDM .ant-tree-node-content-wrapper {
  width: calc(100% - 48px);
}
.CommonFilesInput_itemTree__1XhtV {
  margin: 0;
  width: 100%;
  min-width: 120px;
  display: flex;
  flex: auto;
  height: auto;
  overflow: hidden;
}
.CommonFilesInput_removed__2TBc9 {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}
.CommonFilesInput_item__3ZJ9P {
  padding: 0.25rem 0.5rem;
  margin: 0;
  width: 100%;
  min-width: 120px;
  display: flex;
  flex: auto;
  height: auto;
  overflow: hidden;
}
.CommonFilesInput_content__3F1st {
  display: flex;
  align-items: center;
  flex: 1 1;
  overflow: hidden;
}
.CommonFilesInput_countInfo__kBoAk {
  color: #999999;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
.CommonFilesInput_info__3z2Q9 {
  flex: 1 1;
  margin: 0 2rem 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CommonFilesInput_name__2j6wg {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.2rem 0.5rem 0.2rem 0.2rem;
}
.CommonFilesInput_alreadyExists__383sM {
  color: #faad14;
}
.CommonFilesInput_writable__f3rSR {
  color: #389e0d;
}
.CommonFilesInput_fullAccess__2oE9A {
  color: #389e0d;
}
.CommonFilesInput_forbidden__dNXat {
  color: #cf1322;
}
.CommonFilesInput_revision__xtUFC {
  color: #722ed1;
}
.CommonFilesInput_errors__2WDeB {
  color: #f5222d;
}
.CommonFilesInput_path__19lmi {
  font-size: 0.8rem;
  font-style: italic;
  margin-top: -0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CommonFilesInput_size__2cNPe {
  font-size: 0.8rem;
}
.CommonFilesInput_delete__G6r2n {
  margin-left: 1rem;
}
.CommonFilesInput_title__2hB-j {
  display: flex;
  align-items: center;
  min-height: 1rem;
  font-weight: 600;
}
.CommonFilesInput_titleWrap__L_g8i {
  display: block;
}
.CommonFilesInput_input__1hsRb {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.CommonFilesInput_inheritPermissions__z7NLX {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.CommonFilesInput_inheritPermissions__z7NLX .ant-switch {
  margin: 0 0.5rem 0 0;
}
.CommonFilesInput_statisticsContainer__1MIzM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.CommonFilesInput_infoRow__QTLkQ {
  grid-gap: 0 1rem;
  gap: 0 1rem;
  flex-wrap: wrap;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.CommonFilesInput_statistic__3LFm2 {
  display: flex;
  align-items: center;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}
.CommonFilesInput_statistic__3LFm2 .ant-btn-link {
  color: inherit;
}
.CommonFilesInput_statistic__3LFm2 .ant-btn-link .anticon {
  margin: 0;
}
.CommonFilesInput_checkingMessageLine__1Hxm2 {
  min-height: 1.7rem;
  display: block;
}
.CommonFilesInput_menuMore__3tDdw {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.CommonFilesInput_icons__17i3N {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  margin-right: 0.25rem;
}
.CommonFilesInput_captureInput__29Gp8 {
  display: none !important;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.InvalidItemsGroupResolver_accentWarning__JU7Q2 {
  color: #faad14;
}
.InvalidItemsGroupResolver_accentForbidden__3-SXk {
  color: #cf1322;
}
.InvalidItemsGroupResolver_accentRevision__1ihsm {
  color: #722ed1;
}
.InvalidItemsGroupResolver_description__2InWM {
  font-size: 0.9rem;
  line-height: 0.9rem;
  display: block;
}
.InvalidItemsGroupResolver_statistic__1hUh3 {
  display: flex;
  align-items: center;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}
.InvalidItemsGroupResolver_statistic__1hUh3 .ant-btn-link {
  color: inherit;
}
.InvalidItemsGroupResolver_statistic__1hUh3 .ant-btn-link .anticon {
  margin: 0;
}

.UploadState_itemList__dEtnC {
  max-height: 500px;
  padding-right: 10px;
  padding-bottom: 5px;
}
.UploadState_item__2JGeW {
  border-bottom: 1px dotted #d9d9d9;
  padding-bottom: 4px;
  padding-top: 2px;
}
.UploadState_itemBottom__3M3VU {
  padding-top: 2px;
}
.UploadState_uploadStats__2v2lf {
  justify-content: space-between;
  margin-bottom: 1rem;
}

.DocumentSelectMultipleFormItem_filesGrid__JXn8k {
  max-height: 250px;
}
.DocumentSelectMultipleFormItem_documentRow__2RLDc {
  margin-bottom: 0.5rem;
}
.DocumentSelectMultipleFormItem_pathDisplay___-9vX {
  overflow: auto;
}

.CalendarSettingsForm_filterWrapper__2sDRY {
  display: flex;
  justify-content: space-between;
}
.CalendarSettingsForm_holidayWrapper__SVYkl {
  max-height: 350px;
  overflow: hidden;
}

.EsticonIcon_esticonIcon__gFGdS {
  max-width: 100%;
  max-height: 100%;
  width: 1em;
}
.EsticonIcon_esticonIcon__gFGdS.EsticonIcon_monochrome__2wAwm * {
  fill: currentColor;
}

.PermissionsTab_permission__1tv9i + .PermissionsTab_permission__1tv9i {
  margin-top: 1rem;
}
.PermissionsTab_permissionItem__1Eq4j + .PermissionsTab_permissionItem__1Eq4j {
  margin-top: 0.25rem;
}
.PermissionsTab_switch__2fqjp {
  margin-right: 8px;
}
.PermissionsTab_revisionTag__1Wpno {
  max-width: -webkit-min-content;
  max-width: min-content;
}

.ColorPickerInput_swatch__2OER0 {
  padding: 5px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: inline-flex;
  cursor: pointer;
}
.ColorPickerInput_swatch__2OER0.ColorPickerInput_default__1gAR4 .ColorPickerInput_color__M-gBR {
  height: 20px;
}
.ColorPickerInput_swatch__2OER0.ColorPickerInput_default__1gAR4 .ColorPickerInput_refresh__1JXoE {
  margin-top: 3px;
}
.ColorPickerInput_swatch__2OER0.ColorPickerInput_small__1FTP_ .ColorPickerInput_color__M-gBR {
  height: 14px;
}
.ColorPickerInput_color__M-gBR {
  width: 36px;
  border-radius: 2px;
}
.ColorPickerInput_refresh__1JXoE {
  margin-left: 5px;
}
.ColorPickerInput_popover__1u8cj {
  position: absolute;
  z-index: 2;
}
.ColorPickerInput_cover__3DUiR {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.LabelsListItemForm_listItem__1ePoF {
  box-sizing: border-box;
}
.LabelsListItemForm_hbox__R8mBT {
  margin-bottom: 8px;
}
.LabelsListItemForm_form__2cbt2 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.LabelsListItemForm_form__2cbt2 .LabelsListItemForm_item__2jS26 {
  margin-bottom: 0;
}
.LabelsListItemForm_form__2cbt2 .LabelsListItemForm_name__1qgbH {
  min-width: 150px;
  flex: 1 1 auto;
}
.LabelsListItemForm_form__2cbt2 .LabelsListItemForm_description__1102f {
  flex: 1 0 auto;
  width: 100%;
}
.LabelsListItemForm_form__2cbt2 .LabelsListItemForm_buttons__1ZsDA {
  display: flex;
  width: 100%;
  margin-top: 8px;
  justify-content: flex-end;
}

.HeightMeasureWrap_fullHeightWrap__3qVeW {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
}

.ProjectsTemplates_modalWrap__3SlnJ {
  background-color: #eee;
  padding: 0 1rem;
}
.ProjectsTemplates_masterPanel__2cuCn {
  max-width: 800px;
}
.ProjectsTemplates_settingsContainer__2Enjk {
  margin-top: 1rem;
}
.ProjectsTemplates_tabContent__sYsxy .ant-tabs-content {
  margin-top: 0.8rem;
}

.CategoryTree_categoryTree__3lrBN {
  padding: 1rem;
  border-radius: 0.25rem;
}
.CategoryTree_categoryTree__3lrBN .ant-tree-node-content-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}
.CategoryTree_categoryTreeNode__3YLaE {
  display: flex;
}
.CategoryTree_categoryTreeNodeTitle__1n0zL {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CategoryTree_categoryTreeNodeTitleActions__3UybW {
  flex: 0 0 auto;
}
.CategoryTree_deleteOptionsContainer__1pe3W {
  margin: 0.5rem 0;
}

.ProjectTemplateDirectoryDetail_wrapper__ykTnA {
  width: 800px;
}
.ProjectTemplateDirectoryDetail_directoryIcon__1oZHa {
  margin-right: 0.5rem;
}

.ProjectTemplateRolesTab_warning__1q2AI {
  margin-left: 0.5rem;
}

.ReportUnitSettingsModal_esticonDbSelect__fD9qU {
  width: 100%;
}
.ReportUnitSettingsModal_permissionTree__2tEML .ant-tree-treenode,
.ReportUnitSettingsModal_permissionTree__2tEML .ant-tree-node-content-wrapper {
  width: 100%;
}
.ReportUnitSettingsModal_permissionName__1lVdZ {
  flex-grow: 1;
}
.ReportUnitSettingsModal_permission__3jcIL {
  width: 200px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DashboardTile_leftStrip__3vqIZ {
  position: relative;
}
.DashboardTile_leftStrip__3vqIZ:before {
  content: '';
  display: block;
  position: absolute;
  background: #193d71;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 4px;
  height: 50%;
  left: 3px;
  border-radius: 3px;
}
.DashboardTile_dropdown__1C34- {
  margin-left: 8px;
}
.DashboardTile_dashboardTile__3dDx- {
  background: #ffffff;
  box-shadow: 0 4px 14px 0 #e5e5e5;
}
.DashboardTile_dashboardTileHeader__3A5aC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-bottom: solid 1px #e5eced;
  position: relative;
}
.DashboardTile_projectsTileContent__33rXu {
  padding: 12px;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}
.DashboardTile_dashboardTitle__3x5hs {
  font-size: 0.9rem;
  font-weight: 600;
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.InnerDragTile_tile__3c9rt {
  height: 100%;
}
.InnerDragTile_drop__1eNjW {
  outline: 2px dashed #808080;
}
.InnerDragTile_drag__3cEEl {
  opacity: 0.5;
}

.DragableGrid_container__38dO0 {
  display: flex;
  flex-wrap: wrap;
}
.DragableGrid_container__38dO0 > .DragableGrid_card__3Z4I7 {
  width: 400px;
  max-width: 600px;
  flex-grow: 1;
  margin: 12px;
  min-width: 0;
}

.MovableCard_leftStrip__3_YSf {
  position: relative;
}
.MovableCard_leftStrip__3_YSf:before {
  content: '';
  display: block;
  position: absolute;
  background: #193d71;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 4px;
  height: 50%;
  left: 3px;
  border-radius: 3px;
}
.MovableCard_card__14kLS {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 3px;
}
.MovableCard_title__MXZhX {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  position: relative;
}
.MovableCard_title__MXZhX:before {
  content: '';
  display: block;
  position: absolute;
  background: #193d71;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 4px;
  height: 50%;
  left: 3px;
  border-radius: 3px;
}
.MovableCard_titleText__2oc9H {
  margin-left: 15px;
  margin-top: 8px;
  min-width: 0;
  margin-right: 0.5rem;
  flex: 1 1;
}
.MovableCard_title__MXZhX.MovableCard_draggable__1E745 {
  cursor: move;
}
.MovableCard_menu__EooUW {
  align-self: center;
  margin-right: 5px;
  margin-left: auto;
}
.MovableCard_content__2Xvnl {
  flex-grow: 1;
  min-height: 0;
}
.MovableCard_link__3KLz6 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.Charts_charts__1qLhZ {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: auto;
}
.Charts_middle__1gnzX {
  width: 20px;
}
.Charts_chart__1taks {
  display: inline-block;
  border: 1px solid #c0c0c0;
  flex: 1 1;
  width: 60px;
  max-width: 48%;
}
.Charts_empty__iLeqK {
  margin-bottom: 50px;
}
@media only screen and (max-width: 500px) {
  .Charts_charts__1qLhZ {
    display: none;
  }
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectCard_leftStrip__3j_mS {
  position: relative;
}
.ProjectCard_leftStrip__3j_mS:before {
  content: '';
  display: block;
  position: absolute;
  background: #193d71;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 4px;
  height: 50%;
  left: 3px;
  border-radius: 3px;
}
.ProjectCard_card__3oOpT {
  height: 360px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 900px) {
  .ProjectCard_card__3oOpT {
    height: auto;
  }
}
.ProjectCard_closeButton__1Y_-d {
  line-height: 1rem;
}
.ProjectCard_detail__2E9jz {
  padding: 8px 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ProjectCard_name__zRB21 {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  vertical-align: center;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 0.5rem;
}
.ProjectCard_description__2qB4a {
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 0.4em !important;
}
.ProjectCard_params__22LDp {
  margin-bottom: 1rem;
}
.ProjectCard_tooltip__efowu {
  width: 600px;
  max-width: 90%;
}
.ProjectCard_projectNameTitle__3orGH {
  font-weight: 600;
  margin-bottom: 1rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectsInRealizationOverviewReport_content__2F3_A {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.ProjectsInRealizationOverviewReport_breadcrumbLink__1wkuM {
  cursor: pointer;
  color: #193d71;
}
.ProjectsInRealizationOverviewReport_unitSelect__qsQZ3 {
  width: 300px;
  max-width: 100%;
}
.ProjectsInRealizationOverviewReport_reportWrapper__3AoX_ .dx-header-row {
  background-color: #e8ebfa !important;
  color: black !important;
}
.ProjectsInRealizationOverviewReport_reportWrapper__3AoX_ .dx-header-row .ProjectsInRealizationOverviewReport_centered__1tjWj {
  text-align: center !important;
}
.ProjectsInRealizationOverviewReport_reportWrapper__3AoX_ .dx-data-row .ProjectsInRealizationOverviewReport_kpiCell__1L4-O {
  padding: 0 !important;
}
.ProjectsInRealizationOverviewReport_content__2F3_A {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.ProjectsInRealizationOverviewReport_formItem__29B6T {
  width: 300px;
  max-width: 100%;
  text-align: left;
}
.ProjectsInRealizationOverviewReport_submit__3vntm {
  width: 100%;
}
.ProjectsInRealizationOverviewReport_note__2yDwu {
  justify-content: space-between;
  width: 100%;
}
.dx-data-row td.ProjectsInRealizationOverviewReport_noMargin__1Nijh {
  padding: 0 !important;
}
.dx-data-row td.ProjectsInRealizationOverviewReport_noMargin__1Nijh > div.ProjectsInRealizationOverviewReport_cellBorder__32WTP {
  padding: 5px 8px !important;
}
.dx-data-row td.ProjectsInRealizationOverviewReport_noMargin__1Nijh .ProjectsInRealizationOverviewReport_validationError__1gkT7 {
  background-color: #ff7875;
}
.ProjectsInRealizationOverviewReport_flexTitle__18JDH {
  display: flex;
  justify-content: space-between;
  height: auto;
}
.ProjectsInRealizationOverviewReport_viewSelect__1Qpzq {
  margin-right: 0.5rem;
  width: 180px;
}
.ProjectsInRealizationOverviewReport_filterToolbar__1aEyQ {
  flex: 0 1;
  margin-bottom: 1rem;
}
.ProjectsInRealizationOverviewReport_expandColumnButton__1uqrG {
  position: absolute;
  left: 0;
}
.ProjectsInRealizationOverviewReport_saveViewButton__2hbAT {
  margin-right: 1rem;
}
.ProjectsInRealizationOverviewReport_editViewButton__2ln-F {
  margin-right: 0.5rem;
}
.ProjectsInRealizationOverviewReport_foldableMonths__2ttMy .dx-treelist-text-content {
  position: relative;
  width: 100%;
  padding-left: 24px;
}
.ProjectsInRealizationOverviewReport_editButton__1Fk8r {
  width: 18px !important;
  height: 18px !important;
}
.ProjectsInRealizationOverviewReport_buttonToolbar__2vW_2 > * {
  margin-bottom: 0.5rem;
}

.ProjectsInRealizationReportConfigurationColumnFormItem_dragCard__2R8G4 {
  margin: 0.5rem 0 !important;
}
.ProjectsInRealizationReportConfigurationColumnFormItem_dragIcon__2QXSo {
  cursor: 'move';
}
.ProjectsInRealizationReportConfigurationColumnFormItem_empty__3CLUY {
  margin: 1rem auto;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ReportWidgetForm_formWrapper__1K1Ev {
  display: flex;
  flex-wrap: nowrap;
}
.ReportWidgetForm_generalConfig__244dD {
  margin-right: 1rem;
  padding-right: 1rem;
  width: 400px;
  border-right: 1px solid #e5eced;
}
@media only screen and (max-width: 900px) {
  .ReportWidgetForm_formWrapper__1K1Ev {
    display: block;
  }
  .ReportWidgetForm_generalConfig__244dD {
    width: 100%;
    border-right: none;
  }
  .ReportWidgetForm_directionColumn__3p9ih {
    flex-direction: column;
  }
}

.ReportListPage_content__1Y4Zl {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.ReportListPage_results__iMdP8 {
  flex: 1 1 auto;
  overflow: hidden;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ReportCard_card__2yiGh {
  height: 360px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 900px) {
  .ReportCard_card__2yiGh {
    height: auto;
  }
}
.ReportCard_closeButton__2KAlE {
  line-height: 1rem;
}
.ReportCard_name__2NYuA {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  vertical-align: center;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 0.5rem;
}
.ReportCard_projectNameTitle__3ulGA {
  font-weight: 600;
  margin-bottom: 1rem;
}
.ReportCard_notAvailableError__1jB8Y {
  margin-top: 1rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.POHCharts_chart__2gv_M {
  width: 100%;
  height: 100%;
}
.POHCharts_chart__2gv_M > div {
  width: 100%;
  height: 100%;
}
.POHCharts_empty__3XRJv {
  margin-bottom: 50px;
}
@media only screen and (max-width: 500px) {
  .POHCharts_charts__2zl4X {
    display: none;
  }
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectDocumentationStatisticCharts_chart__3CbKd {
  width: 100%;
  height: 100%;
}
.ProjectDocumentationStatisticCharts_chart__3CbKd > div {
  width: 100%;
  height: 100%;
}
.ProjectDocumentationStatisticCharts_empty__TALtU {
  margin-bottom: 50px;
}
@media only screen and (max-width: 500px) {
  .ProjectDocumentationStatisticCharts_charts__1_05y {
    display: none;
  }
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.AssignmentState_status__6vPTS {
  white-space: nowrap;
  padding: 0 10px;
  height: 24px;
  text-align: center;
}
.AssignmentState_statusNeW__28pph {
  color: #fa541c;
}
.AssignmentState_statusInProgress__3BEqM {
  color: #1890ff;
}
.AssignmentState_statusForApproval__N4LHL {
  color: #fa8c16;
}
.AssignmentState_statusSolved__1KKl4 {
  color: #389e0d;
}
.AssignmentState_statusClosed__2dx6I {
  color: #722ed1;
}
.AssignmentState_statusUnknown__3TbyR {
  color: #999999;
}
.AssignmentState_editIcon__3C8dW {
  margin-left: 0.25rem;
}
.AssignmentState_clickable__3x1Kv {
  cursor: pointer;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.UserAssignmentStatisticsReport_listContainer__2wLbD {
  padding: 0.5rem 1rem 1rem;
  height: 100%;
}
.UserAssignmentStatisticsReport_listContainer__2wLbD > .ant-list {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.UserAssignmentStatisticsReport_listContainer__2wLbD .UserAssignmentStatisticsReport_link__1e94k {
  cursor: pointer;
  color: #162740;
  font-weight: bold;
}
.UserAssignmentStatisticsReport_listContainer__2wLbD .UserAssignmentStatisticsReport_link__1e94k .UserAssignmentStatisticsReport_order__2WN-U {
  display: inline-block;
  padding-right: 0.5rem;
}
.UserAssignmentStatisticsReport_unreaded__1aZyd {
  border-left: 3px solid #162740;
}
.UserAssignmentStatisticsReport_pastDeadline__3aYfG {
  color: #f5222d;
}
.UserAssignmentStatisticsReport_projectIcon__3wk6U {
  margin-right: 0.5rem;
}
.UserAssignmentStatisticsReport_message__3QgYN {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
}
.UserAssignmentStatisticsReport_message__3QgYN .UserAssignmentStatisticsReport_date__AH8hB {
  flex-grow: 1;
  text-align: right;
  margin-left: 0.8rem;
  text-wrap: nowrap;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.UserNotificationStatisticsReport_listContainer__3X0J_ {
  padding: 0.5rem 1rem 1rem;
  height: 100%;
}
.UserNotificationStatisticsReport_listContainer__3X0J_ > .ant-list {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.UserNotificationStatisticsReport_listContainer__3X0J_ .UserNotificationStatisticsReport_link__1S5RX {
  cursor: pointer;
  color: #162740;
}
.UserNotificationStatisticsReport_unreaded__15JSt {
  border-left: 3px solid #162740;
}
.UserNotificationStatisticsReport_message__BQqUr {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
}
.UserNotificationStatisticsReport_message__BQqUr .UserNotificationStatisticsReport_date__2vDHS {
  flex-grow: 1;
  text-align: right;
  margin-left: 0.8rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MainDashboard_scrollableContent__27d7f {
  height: auto;
  display: block;
  overflow: auto;
}
.MainDashboard_projectsButton__2w_9Q {
  text-align: center;
}
.MainDashboard_reportContainer__3cu3I {
  margin-top: 1rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.PageSubmenu_menu__1wMHh {
  background: transparent;
}
.PageSubmenu_menu__1wMHh .ant-menu-inline {
  background: transparent !important;
}
.PageSubmenu_menu__1wMHh .ant-menu-item {
  border-radius: 0.25rem;
  background: #ffffff !important;
}
.PageSubmenu_menu__1wMHh .ant-menu-item::after {
  top: 3px;
  bottom: 3px;
  border: none;
  width: 4px;
  right: 3px;
  border-radius: 2px;
  background: #193d71;
}
.PageSubmenu_menu__1wMHh .ant-menu-item:hover {
  background: #ffffff !important;
  color: #193d71 !important;
  text-decoration: underline;
}
.PageSubmenu_menu__1wMHh .ant-menu-item-selected {
  background: #ffffff !important;
  color: #193d71 !important;
  font-weight: 700;
}
.PageSubmenu_menu__1wMHh .ant-menu-item-selected::after {
  top: 3px;
  bottom: 3px;
  border: none;
  width: 4px;
  right: 3px;
  border-radius: 2px;
  background: #193d71;
}

.CommonHeader_iconBox__1YCLu {
  height: 60px;
  margin: 0;
  background-color: #e7eeff;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CommonHeader_iconBox__1YCLu .anticon {
  font-size: 36px;
  margin-top: 2px;
}
.CommonHeader_nameRow__3R0JH {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  min-height: 2.125rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  min-width: 0;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  line-height: 2rem;
}
.CommonHeader_main__2wvFh {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1 1;
  min-width: 150px;
}
.CommonHeader_buttons__2rliw {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
  align-items: center;
}
.CommonHeader_header__x704e {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  max-width: 100%;
}
.CommonHeader_header__x704e.CommonHeader_minHeight__1ilJU {
  min-height: 3rem;
}
.CommonHeader_header__x704e.CommonHeader_alignTop__2VDt_ {
  align-items: flex-start;
}
.CommonHeader_header__x704e.CommonHeader_paddingLeft__3El-9 {
  padding-left: 1rem;
}
.CommonHeader_header__x704e.CommonHeader_paddingNormal__1hw3n {
  padding: 0.5rem 0 0.5rem 1rem;
}
.CommonHeader_header__x704e.CommonHeader_paddingAll__VRBfE {
  padding: 0.5rem 1rem;
}
.CommonHeader_header__x704e.CommonHeader_marginBottom__2OIMp {
  margin-bottom: 0.5rem;
}

.SimpleOrderId_orderId__14aR- {
  color: #000000;
  font-weight: 900;
  font-size: 20px;
  margin-right: 1em;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.CommonEditable_error__MRbjE {
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
}
.CommonEditable_error__MRbjE::-webkit-input-placeholder {
  color: #ff4d4f !important;
  opacity: 0.5;
}
.CommonEditable_error__MRbjE::placeholder {
  color: #ff4d4f !important;
  opacity: 0.5;
}
.CommonEditable_input__1mjsZ {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  border-color: transparent;
  text-overflow: ellipsis;
  margin: 0.1rem -0.5rem;
  padding: 0 0.5rem;
  min-width: 150px;
  border-radius: 0.25rem;
}
.CommonEditable_input__1mjsZ:hover {
  border-color: #b3b3b3;
}
.CommonEditable_input__1mjsZ:focus {
  border-color: #193d71;
}
.CommonEditable_input__1mjsZ::-webkit-input-placeholder {
  font-style: italic;
  min-height: 1em;
  min-width: 3em;
}
.CommonEditable_input__1mjsZ::placeholder {
  font-style: italic;
  min-height: 1em;
  min-width: 3em;
}
.CommonEditable_input__1mjsZ input {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
}
.CommonEditable_descriptionWrapper__2iTEM {
  position: relative;
  margin: 0 -0.5rem;
  height: 32px;
  width: 100%;
}
.CommonEditable_descriptionWrapper__2iTEM > .ant-input {
  height: 32px;
}
.CommonEditable_textarea__14xp2 {
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  border-color: transparent;
  text-overflow: ellipsis;
  resize: none;
  border-radius: 0.25rem;
}
.CommonEditable_textarea__14xp2:hover {
  border-color: #b3b3b3;
}
.CommonEditable_textarea__14xp2:focus {
  border-color: #193d71;
  height: 6rem;
  position: absolute;
  z-index: 10;
}
.CommonEditable_textarea__14xp2.CommonEditable_disabled__T0E_W {
  cursor: initial !important;
  border-color: transparent !important;
}
.CommonEditable_textarea__14xp2::-webkit-input-placeholder {
  font-style: italic;
  min-height: 1em;
  min-width: 3em;
}
.CommonEditable_textarea__14xp2::placeholder {
  font-style: italic;
  min-height: 1em;
  min-width: 3em;
}
.CommonEditable_container__3c4Tv {
  position: relative;
}
.CommonEditable_flex__-LfSZ {
  flex: 1 1;
}
.CommonEditable_formatedTooltip__2uX6v {
  white-space: pre-line;
  min-width: 250px;
  max-width: 30%;
}

.AssignmentPreview_container__2ZynJ {
  padding: 0;
}
.AssignmentPreview_assignmentName__iYfUi {
  flex-grow: 1;
  font-size: 1.2em;
  font-weight: bold;
}
.AssignmentPreview_ellipsis__lA8cZ {
  flex: 1 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AssignmentPreview_headerStack__OeOo6 {
  align-self: flex-start;
}
.AssignmentPreview_itemsContainer__1gK0E {
  display: grid;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}
.AssignmentPreview_projectName__1DO39 {
  margin: 0 1rem 0.5rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DiscussionAttachmentUploadList_thumbnail__FIf35 {
  height: 60px;
  width: 60px;
  align-self: stretch;
  margin: 0 0.7rem 0.5rem 0;
  flex: 0 0 auto;
}
.DiscussionAttachmentUploadList_icon__3kipG {
  font-size: 60px;
}

.AssignmentDetail_container__1Pinz {
  padding: 0;
}
.AssignmentDetail_assignmentName__1gwuK {
  flex-grow: 1;
  font-size: 1.2em;
  font-weight: bold;
}
.AssignmentDetail_ellipsis__14mhj {
  flex: 1 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.AssignmentDetail_headerStack__3lMxP {
  align-self: flex-start;
}
.AssignmentDetail_itemsContainer__3aar6 {
  display: grid;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}
.AssignmentDetail_projectName__1ZMu- {
  margin: 0 1rem 0.5rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DiscussionChat_content__1Tt6N {
  background-color: #f5f5f5;
  padding: 1rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DiscussionInputAttachments_viewer__1WkeM {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.DiscussionInputAttachments_thumbnail__3LtTX {
  height: 80px;
  width: 80px;
  align-self: stretch;
  flex: 0 0 auto;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.DiscussionInputAttachments_thumbnail__3LtTX.DiscussionInputAttachments_staged__1IObL:after {
  outline-color: #05c05c;
}
.DiscussionInputAttachments_thumbnail__3LtTX.DiscussionInputAttachments_warning__2rfj0:after {
  outline-color: #faad14;
}
.DiscussionInputAttachments_thumbnail__3LtTX .anticon-file-unknown {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DiscussionInputAttachments_thumbnail__3LtTX button {
  display: none;
  position: absolute;
  right: 2px;
  top: 2px;
  color: #f5222d;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.DiscussionInputAttachments_thumbnail__3LtTX:after {
  content: '';
  outline: solid 3px transparent;
  outline-offset: -2px;
  border-radius: 0.25rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  z-index: 10;
  transition: outline-color 0.3s ease-in-out;
}
.DiscussionInputAttachments_thumbnail__3LtTX:not(.DiscussionInputAttachments_staged__1IObL):hover:after {
  outline-color: #193d71;
}
.DiscussionInputAttachments_thumbnail__3LtTX:hover button {
  display: block;
}
.DiscussionInputAttachments_attachButton__1BDxn {
  flex: 0 0 auto;
  align-self: stretch;
}
.DiscussionInputAttachments_stagedWrapper__2TG3B {
  width: 100%;
  height: 100%;
}

.DiscussionInputAttachmentNameForm_imagePreview__2AS8N {
  max-width: 100%;
  max-height: 300px;
  width: auto;
  height: auto;
  margin: 0 auto 25px;
  display: block;
}

.DiscussionLinkAttachmentFormModal_attachmentModal__1EGZH .ant-modal-body {
  padding: 0;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.CommentText_comment__1UrX_ {
  border-radius: 3px;
  border: solid 1px #e5e5e5;
  border-left: solid 5px #ea542d;
  margin: 1rem 0 0 0;
}
.CommentText_comment__1UrX_ .CommentText_attachmentHeader__2IUgH {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.2rem 0 0;
}
.CommentText_comment__1UrX_ .CommentText_attachmentHeader__2IUgH button {
  line-height: 14px;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.CommentText_comment__1UrX_ .CommentText_message__drnv0 {
  padding: 0.5em;
  min-height: 2em;
}
.CommentText_comment__1UrX_ .CommentText_addition__1BJUn {
  min-height: 2em;
  border-top: solid 1px #e5e5e5;
}
.CommentText_comment__1UrX_ .CommentText_additionTitle__1P6OB {
  padding: 0 0 0 0.5rem;
  display: block;
}
.CommentText_comment__1UrX_ .CommentText_viewer__1oCBO {
  margin-left: 0.5rem;
}
.CommentText_emptyPlaceholder__3j4HF {
  font-style: italic;
  color: #999999;
  cursor: pointer;
}
.CommentText_messageTextArea__25db7 {
  padding: 0.5em;
  border-radius: 0 3px 3px 0;
  border: none;
  white-space: pre-wrap;
  max-height: none !important;
}
.CommentText_editable__3rf8T {
}

.DiscussionSendButton_button__1dL-w {
  margin-left: auto;
  min-width: 46px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DiscussionInput_container__27dZL {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: solid 1px #e8e8e8;
}
.DiscussionInput_header__32vn- {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
  align-items: center;
  flex-wrap: wrap;
}
.DiscussionInput_header__32vn- > :last-child {
  margin-left: auto;
}
.DiscussionInput_input__2Pg4o {
  flex: 1 1;
  min-width: 0;
}
.DiscussionInput_inputArea__1YKmc {
  flex: 1 1;
  min-width: 0;
}
.DiscussionInput_notifyUserContainer__3tYPf {
  font-size: 0.8rem;
  font-weight: 600;
  color: #666666;
  margin: 0.25rem 0;
}
.DiscussionInput_attachmentWarning__PZihT {
  padding: 0 0.5rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.AuthorRelationWrapper_wrapper__33m3y {
  display: flex;
  align-items: center;
  justify-content: center;
}
.AuthorRelationWrapper_innerWrap__28ZDy {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AuthorRelationWrapper_noPadding__3RxBE {
  padding: 0;
}
.AuthorRelationWrapper_border__2dDGy {
  border-radius: 50%;
  position: absolute;
  bottom: -2px;
  width: auto;
  left: 80%;
  display: flex;
}
.AuthorRelationWrapper_border__2dDGy > * {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  border: solid 1px #ffffff;
  margin-left: -3px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.DiscussionNote_comment__gqQce {
  margin: 0 3rem 0.5rem 0;
  margin-right: auto;
  max-width: 90%;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  color: #000000;
  background: #ffffff;
}
.DiscussionNote_comment__gqQce.DiscussionNote_editing__WYi5q {
  width: 90%;
}
.DiscussionNote_messageInnerWrap__1DPiw {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.DiscussionNote_messageHeader__4G7Ow {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.DiscussionNote_message__24cmC {
  margin: 0;
  padding: 0;
  color: #000000;
  white-space: pre-wrap;
  max-height: 200px;
  overflow: auto;
}
.DiscussionNote_myComment__1x5zn {
  margin-right: 0;
  margin-left: auto;
  background: #e8ebfa;
}
.DiscussionNote_header__1MpUJ {
  display: flex;
  flex-wrap: wrap-reverse;
  grid-gap: 0 2rem;
  gap: 0 2rem;
  align-items: center;
}
.DiscussionNote_author__24KPs {
  font-weight: 600;
  word-break: break-all;
}
.DiscussionNote_subInfo__24E8y {
  font-weight: 600;
  font-size: 0.9em;
  color: #666666;
  cursor: default;
}
.DiscussionNote_menuContainer__zf06E {
  margin-left: auto;
}
.DiscussionNote_closeEdit__3JPNI {
  margin-right: 0.3rem;
}
.DiscussionNote_discardedNote__3g2Qd {
  margin: 0.2rem auto 0.2rem 0;
  background-color: lightgray;
  display: inline-block;
  font-style: italic;
  border-radius: 0.2rem;
  padding: 0 1rem;
}
.DiscussionNote_commentInput__3RQNQ {
  margin: 0 0;
}
.DiscussionNote_notifiedUsers__2b5xs {
  font-size: 0.8rem;
  font-weight: 600;
  color: #666666;
  margin: 0 0 0.25rem 0;
}

.AssignmentProjectCheck_backdrop__2VKjB {
  background-color: rgba(128, 128, 128, 0.25);
  -webkit-backdrop-filter: blur(0.25rem);
          backdrop-filter: blur(0.25rem);
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MessageCenterProjectsMenu_collapse__kqznH {
  border-top: 1px solid #e8e8e8;
  height: 100%;
}
.MessageCenterProjectsMenu_collapse__kqznH .ant-collapse-content-box {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.MessageCenterProjectsMenu_panel__Rz11y {
  padding: 0 !important;
  margin: 0;
  background: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.MessageCenterProjectsMenu_panel__Rz11y .ant-collapse-content-active {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.MessageCenterProjectsMenu_menuLabel__1S7hy {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.MessageCenterProjectsMenu_menuLabel__1S7hy > .ant-menu-title-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
}
.MessageCenterProjectsMenu_projectName__25L4C {
  flex-grow: 1;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MessageCenterSettings_infoIcon__3ev-V {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.MessageCenterSettings_title__2vcEh {
  padding-top: 0.3rem;
}
.MessageCenterSettings_filterOrder__2Oe9M {
  display: flex;
  flex-wrap: nowrap;
}
.MessageCenterSettings_container__2FEKT {
  background: #ffffff;
  border-radius: var(--box-border-radius);
}
.MessageCenterSettings_settingsDetail__FeD-h {
  padding-top: 1rem;
}
.MessageCenterSettings_settingsDetail__FeD-h .ant-row {
  padding: 0.5rem;
  line-height: 1.2rem;
  align-items: center;
}
.MessageCenterSettings_settingsDetail__FeD-h .ant-row:nth-child(2n) {
  background-color: #f1f1f1;
}
.MessageCenterSettings_settingsDetail__FeD-h .ant-col + .ant-col {
  text-align: center;
}
.MessageCenterSettings_menuLabel__1fSy1 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.MessageCenterSettings_menuLabel__1fSy1 > .ant-menu-title-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
}
.MessageCenterSettings_projectName__2e0xB {
  flex-grow: 1;
}
.MessageCenterSettings_panelContent__Ndrae {
  margin-left: 1.5rem;
}
.MessageCenterSettings_badgedLabel__3z9ee {
  font-weight: bold;
  margin-right: 0.5rem;
}

.WrapButton_wrap__33_ma {
  height: auto;
  white-space: normal;
  line-height: 1.2;
  padding-block: 0.1rem;
  min-height: 32px;
}
.WrapButton_wrap__33_ma.ant-btn-sm {
  min-height: 24px;
}
.WrapButton_wrap__33_ma.ant-btn-lg {
  min-height: 48px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MessageDetail_content__t2EUs {
  height: 100%;
  border-radius: 0.25rem;
  background: #e5e5e5;
}
.MessageDetail_empty__2uS40,
.MessageDetail_error__140G5 {
  margin-top: 40%;
  text-align: center;
}

.MessageViewerDetailGrid_detailGrid__Rr91F {
  margin-top: 2.5rem;
}
.MessageViewerDetailGrid_detailGrid__Rr91F .ant-row {
  margin-bottom: 0.7rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MessageViewer_header__2klc8 {
  display: flex;
  background: #162740;
  justify-content: space-around;
  padding-top: 2rem;
  padding-inline: 0.5rem;
  border-radius: 0.25rem 0.25rem 0 0;
}
.MessageViewer_header__2klc8 .MessageViewer_headline__1ZprG {
  background: #fff;
  padding: 3rem 2rem 2rem;
  margin: 0 0.2rem;
  border-radius: 5px 5px 0 0;
  max-width: 680px;
  width: 100%;
  text-align: center;
}
.MessageViewer_body__2Uz2M {
  display: flex;
  justify-content: space-around;
  padding-bottom: 1rem;
  background: #e5e5e5;
  border-radius: 0 0 0.25rem 0.25rem;
}
.MessageViewer_body__2Uz2M .MessageViewer_content__1_9SY {
  background: #fff;
  padding: 2rem 2rem 3rem;
  margin: 0 0.2rem;
  border-radius: 0 0 5px 5px;
  max-width: 680px;
  width: 100%;
}
.MessageViewer_description__3WgCp {
  display: flex;
}
.MessageViewer_links__hMt9Q {
  overflow-wrap: break-word;
  text-indent: -1rem;
  margin-left: 1rem;
}

.OrderFilterToolbar_toolbar__3TWI4 {
  border: 0;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ListItem_listItem__njl20 {
  cursor: pointer;
  margin-bottom: 4px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  position: relative;
  border-radius: 0.25rem;
  background: #ffffff;
}
.ListItem_listItem__njl20.ListItem_selected__1JfP0 {
  border: 1px solid #193d71;
}
.ListItem_listItem__njl20.ListItem_selected__1JfP0 .ListItem_listItemActions__2D3n7 {
  display: flex;
}
.ListItem_listItem__njl20:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  background: #f1f1f1;
}
.ListItem_listItem__njl20:hover .ListItem_listItemActions__2D3n7 {
  display: flex;
}
.ListItem_listItemContent__3FuGh {
  display: flex;
}
.ListItem_listItemBody__3Fytu {
  flex: 1 1 auto;
  overflow: hidden;
}
.ListItem_listItemActions__2D3n7 {
  flex: 0 0 auto;
  align-self: center;
  display: none;
  flex-wrap: wrap;
}

.BasicListItem_wrapper__z57lP {
  padding: 8px;
}
.BasicListItem_name__32pZB {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.BasicListItem_description__1cfTm {
  font-weight: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.BasicListItem_nonActiveRole__1A2i3 {
  color: red;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MessageListItem_container__3eNZR {
  padding: 0.6rem;
}
.MessageListItem_container__3eNZR.MessageListItem_active__1iEY2 {
  background: #e5e5e5;
}
.MessageListItem_headline__1oFDp {
  display: flex;
  justify-content: space-between;
}
.MessageListItem_title__1D7WB {
  flex: 1 1;
}
.MessageListItem_title__1D7WB .anticon {
  margin-right: 1rem;
}
.MessageListItem_highlight__1C1-q {
  font-weight: 600;
}
.MessageListItem_dateline__2fWqt {
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
}
.MessageListItem_createDate__2ruLs {
  color: #999999;
  text-align: right;
}

.MessageGrid_pagination__3cuC8 {
  padding: 0.7rem 1rem;
}
.MessageGrid_container__2c9hW {
  background-color: #fff;
  border-radius: 0.25rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectListPageRow_wrapper__3CLw1 {
  height: 54px;
  min-width: 500px;
}
.ProjectListPageRow_row__1vjXP {
  height: 100%;
  padding: 3px 6px 3px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 0;
  border-bottom: solid 1px #e6e8f1;
}
.ProjectListPageRow_row__1vjXP.ProjectListPageRow_selected__3Mx4a {
  background-color: #f1f1f1;
}
.ProjectListPageRow_row__1vjXP:hover {
  background: #e0e0e0;
}
.ProjectListPageRow_row__1vjXP:hover .ProjectListPageRow_checkbox__2_Nnf:before {
  display: flex;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_thumbnail__33n4p {
  width: 55px;
  margin-right: 1rem;
  flex: 0 0 auto;
  cursor: pointer;
  align-self: stretch;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_thumbnail__33n4p .anticon {
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_main__3ahrJ {
  flex: 1 1 auto;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  display: flex;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_main__3ahrJ .ProjectListPageRow_nameLabels__3X4Vy {
  display: flex;
  width: 100%;
  font-weight: 700;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_main__3ahrJ .ProjectListPageRow_nameLabels__3X4Vy .ProjectListPageRow_name__3kYA3 {
  font-size: 16px;
  line-height: 22px;
  margin-right: 8px;
  flex: 0 1 auto;
  white-space: nowrap;
  min-width: 50px;
  text-overflow: ellipsis;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_main__3ahrJ .ProjectListPageRow_nameLabels__3X4Vy .ProjectListPageRow_labels__135Bn {
  margin-right: 8px;
  flex: 1 2 auto;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 4px;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_main__3ahrJ .ProjectListPageRow_description__3mbmI {
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.5rem;
  display: flex;
  width: 100%;
  cursor: pointer;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_date__2Ywce {
  margin-right: 8px;
  flex: 0 0 auto;
}
.ProjectListPageRow_row__1vjXP .ProjectListPageRow_author__1Q9ZF {
  margin-right: 8px;
  flex: 0 0 auto;
}
.ProjectListPageRow_tag__3-NxB {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 0 auto;
  max-width: 130px;
  min-width: 0;
}
.ProjectListPageRow_restore__3XXrf {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProjectListPageRow_nameRow__34JG3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  max-width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectsListPage_title__21SSb {
  margin: 24px 48px 0 48px;
}
.ProjectsListPage_projectCard__2eFbn:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.ProjectsListPage_sider__1KrTy {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100dvh;
  position: fixed;
  left: 0;
}
.ProjectsListPage_sider__1KrTy .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.ProjectsListPage_sider__1KrTy::-webkit-scrollbar {
  display: none;
}
.ProjectsListPage_menu__3hi6k {
  flex: auto;
}
.ProjectsListPage_subMenu__2a2co {
  padding-left: 48px !important;
  line-height: 30px !important;
  height: 30px !important;
}
.ProjectsListPage_menuBottom__12Fd2 .ant-menu-item:not(:last-child) {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  margin: 0 !important;
}
.ProjectsListPage_menuItemCompany__wzhkW {
  margin-top: 24px !important;
  margin-bottom: 16px !important;
  font-size: 12px !important;
}
.ProjectsListPage_layoutContent__11R97 {
  display: flex;
  flex-direction: column;
}
.ProjectsListPage_breadcrumbs__2yKoW {
  padding: 8px 24px;
}
.ProjectsListPage_content__3L2_E {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.ProjectsListPage_results__1cyxy {
  flex: 1 1 auto;
  overflow: hidden;
}
.ProjectsListPage_mainContent__uuO2p {
  margin-left: 200px;
  transition: margin-left 0.2s;
}
.ProjectsListPage_mainContent__uuO2p.ProjectsListPage_collapsed__2a33p {
  margin-left: 80px;
}
.ProjectsListPage_scrollableContent__1dYph {
  height: auto;
  display: block;
  overflow: auto;
}
.ProjectsListPage_scrollableContentInner__2DCRm {
  height: auto;
  display: block;
  overflow: hidden;
  background: #ececec;
}
.ProjectsListPage_projectsTile__2JV9m {
  margin: 1rem;
}

.ProjectsGridPage_wrapper__2EdPG {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-height: 100%;
  min-height: 0;
  min-width: 0;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.ProjectsGridPage_wrapper__2EdPG .dx-header-row .ProjectsGridPage_columnA__5E_ss {
  background-color: #44546a !important;
  color: white !important;
}
.ProjectsGridPage_wrapper__2EdPG .dx-header-row .ProjectsGridPage_columnB__3LjFr {
  background-color: #f79646 !important;
  color: black !important;
}
.ProjectsGridPage_content__MSPzA {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.ProjectsGridPage_organizationSelect__3T864 {
  min-width: 150px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ReportDetailPage_content__neS4I {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
.ReportDetailPage_breadcrumbLink__I_l7g {
  cursor: pointer;
  color: #193d71;
}
.ReportDetailPage_unitSelect__3BAZy {
  width: 300px;
  max-width: 100%;
}
.ReportDetailPage_reportWrapper__3nxPq .dx-header-row {
  background-color: #e8ebfa !important;
  color: black !important;
}
.ReportDetailPage_reportWrapper__3nxPq .dx-header-row .ReportDetailPage_centered__qtRBq {
  text-align: center !important;
}
.ReportDetailPage_reportWrapper__3nxPq .dx-data-row .ReportDetailPage_kpiCell__2eAfx {
  padding: 0 !important;
}

.KpiSettingsToolbar_kpiList__ZbHnN {
  margin-right: 0.5rem;
}

.SharedReportWidgetModal_sharedWidgetRow__1BRjr {
  width: 100%;
}
.SharedReportWidgetModal_iconPanel__3AiSC {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectsInRealizationNote_comment__2Tw_g {
  margin: 0 0 1.5rem 0;
  margin-right: auto;
  border-radius: 0.25rem;
  color: #000000;
  background: #ffffff;
  width: 100%;
}
.ProjectsInRealizationNote_message__Ne40A {
  margin: 0;
  padding: 0;
  color: #000000;
  white-space: pre-wrap;
  max-height: 200px;
  overflow: auto;
}
.ProjectsInRealizationNote_header__15R8q {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap-reverse;
  grid-gap: 0 2rem;
  gap: 0 2rem;
  align-items: center;
}
.ProjectsInRealizationNote_subInfo__KbKSu {
  font-weight: 600;
  font-size: 0.9em;
  color: #666666;
  cursor: default;
}
.ProjectsInRealizationNote_menuContainer__1PySt {
  margin-left: auto;
}
.ProjectsInRealizationNote_closeEdit__1JqSJ {
  margin-right: 0.3rem;
}
.ProjectsInRealizationNote_commentInput__23mbY {
  margin: 0.3rem 0 0;
  display: flex;
  justify-content: space-between;
}
.ProjectsInRealizationNote_commentInput__23mbY > textarea {
  margin-right: 0.5rem;
}
.ProjectsInRealizationNote_newNote__2ZKwB {
  margin-right: 0.5rem;
}
.ProjectsInRealizationNote_notePanel__Y4Z_E {
  max-height: 400px;
  margin-bottom: 1rem;
}
.ProjectsInRealizationNote_hubIcon__3SKwa {
  max-width: 100%;
  max-height: 100%;
  width: 1em;
}
.ProjectsInRealizationNote_headerGap__2g78E {
  grid-gap: 1rem;
  gap: 1rem;
}
.ProjectsInRealizationNote_noteOrder__2bmR8 {
  font-weight: bold;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectsInRealizationPairingNotes_projectSelect__29yYp {
  width: 100%;
  margin-right: 0.2rem;
}
.ProjectsInRealizationPairingNotes_selectDescription__29NI3 {
  display: flex;
  flex-direction: column;
}
.ProjectsInRealizationPairingNotes_projectWrapper__3SyeC {
  display: flex;
  flex-wrap: nowrap;
}
.ProjectsInRealizationPairingNotes_projectRow__3x7QR {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e5eced;
}
.ProjectsInRealizationPairingNotes_projectRowHeader__3XwHV {
  padding: 0.5rem 1rem 1rem;
  border-bottom: 1px solid #b3b3b3;
}
.ProjectsInRealizationPairingNotes_projectRowHeader__3XwHV > .ant-col {
  font-weight: 600;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectsInRealizationNote_newNote__2yESr {
  margin-right: 0.5rem;
}
.ProjectsInRealizationNote_message__2jpOJ {
  margin: 0;
  padding: 0;
  color: #000000;
  white-space: pre-wrap;
  max-height: 200px;
  overflow: auto;
}
.ProjectsInRealizationNote_commentInput__1Us_l {
  margin: 0.3rem 0 0;
  display: flex;
  justify-content: space-between;
}
.ProjectsInRealizationNote_commentInput__1Us_l > textarea {
  margin-right: 0.5rem;
}

.MDProjectCreateForm_slelctWidth__O23Rs {
  width: 100%;
}

.MDProjectApprovalForm_projectSelectionLabel__CozMC .ant-form-item-required {
  width: 100%;
}
.MDProjectApprovalForm_projectSelectionLabel__CozMC .MDProjectApprovalForm_labelText__2kde5 {
  flex-grow: 1;
}
.MDProjectApprovalForm_identificationSelect__25Rek {
  width: 100%;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MdProjectListSelectFormItem_filesGrid__39LT0 {
  max-height: 250px;
  min-width: 200px;
}
.MdProjectListSelectFormItem_documentRow__27bKu {
  margin-bottom: 0.5rem;
  background: #ffffff;
}
.MdProjectListSelectFormItem_documentRow__27bKu:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}
.MdProjectListSelectFormItem_pathDisplay__e0p0D {
  overflow: auto;
}
.MdProjectListSelectFormItem_centered__3TmqX {
  text-align: center;
}
.MdProjectListSelectFormItem_checkItem__2D3p8 {
  min-width: 30px;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MDProjectApprovalListModal_meetingRow__1piBV {
  padding-left: 0.5rem;
  background: #ffffff;
}
.MDProjectApprovalListModal_meetingRow__1piBV:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}
.MDProjectApprovalListModal_meetingList__1BPbA {
  max-height: calc(75vh - 200px);
}
.MDProjectApprovalListModal_toolbar__1RPd1 > div {
  display: inline-block;
  vertical-align: -3px;
  margin-right: 0.3rem;
}

.MDRoleDisplay_roleUser__2gNU8 {
  padding-bottom: 0.5rem;
  min-width: 500px;
}
.MDRoleDisplay_wrapper__UEz57 {
  display: flex;
  align-items: flex-start;
}
.MDRoleDisplay_infoRow__iedht {
  padding-bottom: 0.25rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MDProjectCardModal_collapse__3he-I {
  background-color: #fbfbfb;
}
.MDProjectCardModal_collapse__3he-I:not(:last-child) {
  border-bottom: 1px solid #b3b3b3 !important;
}
.MDProjectCardModal_formModal__2JcNt .ant-page-header {
  padding: 0 0.5rem 2rem !important;
}
.MDProjectCardModal_mdFormItem__3Dh0a {
  min-width: 200px;
}
.MDProjectCardModal_mdFormItem__3Dh0a .ant-form-item-label,
.MDProjectCardModal_mdFormItem__3Dh0a.MDProjectCardModal_noInput__22TEl > .MDProjectCardModal_label__2pNP6 {
  min-width: 200px;
}
.MDProjectCardModal_mdFormItem__3Dh0a.MDProjectCardModal_noInput__22TEl > div {
  display: inline-flex;
  padding-bottom: 1rem;
}
.MDProjectCardModal_mdFormItem__3Dh0a .MDProjectCardModal_priceInput__fy2G2 {
  width: 180px;
  margin-right: 0.5rem;
}
.MDProjectCardModal_mdFormSectionWrapper__2WT0K {
  flex-wrap: wrap;
}

.MDSetCommentingDivisionsModal_checkboxGrid__2ohyO {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MDApprovedProjectsReportPage_reportPanel__1beHH {
  background-color: #ffffff;
  height: 100%;
}
.MDApprovedProjectsReportPage_filterToolbar__26tJ4 {
  flex: 0 1;
  margin-bottom: 1rem;
}
.MDApprovedProjectsReportPage_reportWrapper__EGKIa {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.MDApprovedProjectsReportPage_pageContent__1eCaF {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.MDApprovedProjectsReportPage_buttonToolbar__1CYob {
  padding: 0.5rem 0 0.5rem 0.5rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.MainModule_layoutContent__2FaMa {
  padding: 8px 8px 0;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
}
.MainModule_content__3S9pE {
  display: flex;
  flex: auto;
  overflow: hidden;
}
.MainModule_mainContent__1dg1N {
  height: 100dvh;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectStatusPage_page__2H9Sf {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProjectStatusPage_box__2hKlV {
  text-align: center;
}
.ProjectStatusPage_box__2hKlV .anticon {
  font-size: 120px;
}
.ProjectStatusPage_box__2hKlV .anticon.ProjectStatusPage_loading__AZpN5 {
  color: #1890ff;
}
.ProjectStatusPage_box__2hKlV .anticon.ProjectStatusPage_check__sGmwY {
  color: #52c41a;
}
.ProjectStatusPage_box__2hKlV .anticon.ProjectStatusPage_close__GK99x {
  color: #fa541c;
}
.ProjectStatusPage_status__1I9ml {
  font-size: 24px;
  font-weight: 400;
  margin-top: 16px;
}

.DocumentCategoryTreeNodeDeleteForm_errorMessageWrap__1TYXA {
  margin-bottom: 1rem;
}

.EsticonDetail_mainPanel__WdbXp {
  padding-right: 0.5rem;
  overflow-x: hidden !important;
}
.EsticonDetail_mainPanel__WdbXp > *:not(:last-child) {
  margin-bottom: 1rem;
}
.EsticonDetail_projectDetail__12LJc {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.EsticonMaskInput_inputWrapper__3VdJd {
  display: flex;
  flex-direction: column;
}
.EsticonMaskInput_inputErrorBorder__Wxa_8 {
  border-color: red;
}
.EsticonMaskInput_errorWrapper__2xzQM {
  position: relative;
}
.EsticonMaskInput_errorMessage__3HNop {
  position: absolute;
  min-width: 250px;
}

.DocumentView_icon__3am8v {
  width: 48px;
  color: #08c;
}
.DocumentView_toolbar__lTynB {
  padding: 0 !important;
}
.DocumentView_documentInfo__26AnN {
  height: 60px;
}

.EsticonCreate_selectProject__ZuJ0U {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.EsticonCreate_selectItem__1ajCa {
  display: flex;
  flex-direction: column;
}
.EsticonCreate_selectItem__1ajCa > span {
  line-height: 1.8rem;
  height: 1.5rem;
}
.EsticonCreate_label__dV5PQ {
  margin-bottom: 5px;
}

.ExternAppAdd_icon__bmU_7 {
  width: 1rem;
  height: 1rem;
}
.ExternAppAdd_disabled__3I3QV {
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
}

.TextPageSetForm_icon__qrMCQ {
  width: 48px;
  color: #08c;
}
.TextPageSetForm_richTextEditor__27aFV {
  height: 400px;
  margin-bottom: 2rem;
  line-height: 1.5rem;
}

.ApplicationUrl_icon__2wkZG {
  width: 48px;
  color: #08c;
}
.ApplicationUrl_breakURL__1i7rj {
  word-wrap: break-word;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectDashboardPage_scrollableContent__3hB6g {
  height: auto;
  overflow: auto;
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.ProjectDashboardPage_tilesRow__1SHXF {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.ProjectDashboardPage_defaultCursor__3IfQP {
  cursor: default !important;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.SessionListItem_container__3uZpC {
  padding: 0.2rem 0.6rem;
}
.SessionListItem_container__3uZpC.SessionListItem_active__2Tk8e {
  background: #e5e5e5;
}
.SessionListItem_headline__3O2BW {
  display: flex;
  justify-content: space-between;
}
.SessionListItem_title__3WKnG {
  flex: 1 1;
}
.SessionListItem_title__3WKnG .anticon {
  margin-right: 1rem;
}
.SessionListItem_highlight__3iACV {
  font-weight: 600;
}
.SessionListItem_dateline__2IjRv {
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
}
.SessionListItem_createDate__1MN2U {
  color: #999999;
  text-align: right;
}

.SessionTab_table__3N2iw {
  margin-bottom: 8px;
}
.SessionTab_list__2M-dR {
  max-height: 500px;
}
.SessionTab_pagination__gXinE {
  padding: 0.7rem 1rem;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ProjectModule_content__gq2yR {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
}
.ProjectModule_layoutContent__kS7cy {
  margin: 0.5rem;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
}
.ProjectModule_mainContent__2tAgw {
  height: 100dvh;
}
.ProjectModule_spinBox__3JVRE {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.LoadingPage_screen__1Hjwe {
  width: 100dvw;
  height: 100dvh;
  background-color: #e6f7ff;
  text-align: center;
  padding-top: calc((100vh / 2) - 30px);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.LoginErrorPage_screen__1oBxx {
  width: 100dvw;
  height: 100dvh;
  background-color: #e6f7ff;
  text-align: center;
  padding-top: calc((100vh / 2) - 30px);
}

.ProjectInvitationAcceptPage_wrap__1PKuQ {
  overflow: auto;
  margin: 0 auto;
}
.ProjectInvitationAcceptPage_box__2A5x7 {
  max-width: 500px;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.ProjectInvitationAcceptPage_title__2nhWM {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.ProjectInvitationAcceptPage_info__1G7uo {
  font-size: 18px;
  margin: 36px 0;
  text-align: center;
}
.ProjectInvitationAcceptPage_message__2YFJ5 {
  margin-bottom: 16px;
  border-left: 10px solid #dfe1e5;
  padding-left: 8px;
}
.ProjectInvitationAcceptPage_accept__1wCO3 {
  text-align: center;
  font-weight: 700;
}
.ProjectInvitationAcceptPage_accept__1wCO3 > button {
  margin-top: 16px;
}
.ProjectInvitationAcceptPage_projectName__3mhvM {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.SharedDownloadPage_header__HYrap {
  overflow-x: auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #162740;
}
.SharedDownloadPage_logo__31lMp {
  margin: auto;
}
.SharedDownloadPage_content__2u7H8 {
  max-width: 800px;
  margin: 30px auto 30px;
  padding: 20px;
  display: flex;
  flex: 1 1;
}
.SharedDownloadPage_spin__3O8Ht {
  margin-top: 48px;
  margin-left: 220px;
}
.SharedDownloadPage_download__k6rHW {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.SharedDownloadPage_pin__33RPL {
  flex: 1 1;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.SharedDownloadPage_row__3Gm6L {
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  max-width: 540px;
}
@media screen and (max-width: 400px) {
  .SharedDownloadPage_row__3Gm6L {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.SharedDownloadPage_name__RV25T {
  flex: 1 1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.SharedDownloadPage_wrapper__3NiTz {
  min-height: 200px;
}
.SharedDownloadPage_table__1Tgg0 {
  max-width: initial;
}
.SharedDownloadPage_error__38OHn {
  max-width: 100%;
}
.SharedDownloadPage_label__3Kq4I {
  min-width: 100px;
  margin-right: 5px;
}
.SharedDownloadPage_value__3r7r4 {
  margin-left: 5px;
}

.AccessTokenExpiredModal_modal__2wdZJ {
  text-align: center;
}
.AccessTokenExpiredModal_icon__3OkdK {
  margin-right: 16px;
}
.AccessTokenExpiredModal_error__2g4vu {
  margin-top: 16px;
  text-align: left;
}

.Main_pageContainer__1ts5T {
  width: 100dvw;
  height: 100dvh;
}

.UnsupportedBrowserCheck_buttonContinue__3qMI7 {
  margin-top: 2em;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
:root {
  --box-border-radius: 0.25rem;
}
:focus {
  outline: 0;
}
.ant-menu-item {
  transition: none;
}
.ant-menu-submenu-title {
  transition: none;
}
.ant-table-column-sorter .anticon-caret-up {
  font-size: 16px;
}
.ant-table-column-sorter .anticon-caret-down {
  font-size: 16px;
}
.ant-breadcrumb-link > a > .anticon + span {
  margin-left: 4px;
}
/* SplitPane  */
.Resizer {
  background: #e6e8f1;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
.Resizer:hover {
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
/* Custom styled scrollbar  */
.scrollbar::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #193d71;
  background-clip: padding-box;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #193d71;
}
.scrollbar::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}
.ant-tree.ant-tree-show-line.hide-file-icon li span.ant-tree-switcher-noop {
  background: transparent;
}
.ant-tree.ant-tree-show-line.hide-file-icon li:before {
  content: ' ';
  width: 1px;
  border-left: 1px solid #d9d9d9;
  height: 100%;
  position: absolute;
  left: 12px;
  top: 0;
  margin: 0;
}
.ant-tree.ant-tree-show-line.hide-file-icon li:first-child:before {
  top: 6px;
  height: calc(100% - 6px);
}
.ant-tree.ant-tree-show-line.hide-file-icon li:last-child:before {
  height: 16px;
}
.ant-tree.ant-tree-show-line.hide-file-icon li:first-child:last-child:before {
  height: 10px;
}
.ant-tree.ant-tree-show-line.hide-file-icon li .ant-tree-switcher-noop > i {
  visibility: hidden;
}
.ant-tree.ant-tree-show-line.hide-file-icon li .ant-tree-switcher-noop:after {
  content: ' ';
  height: 1px;
  border-bottom: 1px solid #d9d9d9;
  width: 10px;
  position: absolute;
  left: 12px;
  top: 50%;
  margin: 0;
}
.Pane {
  min-width: 0;
}
.ant-tag > a:first-child:last-child {
  margin: 0;
  padding: 0;
}
.editable-input {
  border: solid 1px transparent !important;
}
.editable-input:hover,
.editable-input:focus {
  border: solid 1px #193d71 !important;
}

