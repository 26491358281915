/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.collapse {
  background-color: #fbfbfb;
}
.collapse:not(:last-child) {
  border-bottom: 1px solid #b3b3b3 !important;
}
.formModal :global(.ant-page-header) {
  padding: 0 0.5rem 2rem !important;
}
.mdFormItem {
  min-width: 200px;
}
.mdFormItem :global(.ant-form-item-label),
.mdFormItem.noInput > .label {
  min-width: 200px;
}
.mdFormItem.noInput > div {
  display: inline-flex;
  padding-bottom: 1rem;
}
.mdFormItem .priceInput {
  width: 180px;
  margin-right: 0.5rem;
}
.mdFormSectionWrapper {
  flex-wrap: wrap;
}
