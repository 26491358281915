.dropIndicator {
  --height: 3px;
  display: block;
  height: var(--height);
  margin-bottom: calc(var(--height) * -1);
  width: 100%;
  outline: none;
  background: transparent;
  align-self: stretch;
  border-radius: 0.2rem;

  &:last-child {
    margin-bottom: 0;
    margin-top: calc(var(--height) * -1);
  }

  &.dropTarget {
    background: blue;
    position: relative;
    --radius: 6px;

    &:before {
      content: '';
      position: absolute;
      top: calc(var(--radius) * -1 + 1.5px);
      left: calc(var(--radius) * -2 + 6px);
      border-radius: var(--radius);
      height: calc(var(--radius) * 2);
      width: calc(var(--radius) * 2);
      display: block;
      background: white;
      border: solid 3px blue;
    }

    &:after {
      content: '';
      position: absolute;
      top: calc(var(--radius) * -1 + 1.5px);
      right: calc(var(--radius) * -2 + 6px);
      border-radius: var(--radius);
      height: calc(var(--radius) * 2);
      width: calc(var(--radius) * 2);
      display: block;
      background: white;
      border: solid 3px blue;
    }
  }
}

@primary-color: rgb(25,61,113);