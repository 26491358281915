/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.documentName {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
}
.documentName a:hover {
  text-decoration: underline;
}
.main {
  flex: 1;
  overflow: hidden;
  min-width: 90px;
  display: flex;
  flex-direction: column;
}
.main .nameLabels {
  display: flex;
}
