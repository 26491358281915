/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.categoryFilterUsedItem {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background: #e5e5e5;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  flex-direction: column;
  max-width: 300px;
}
.categoryFilterUsedItem .nodeItem {
  border-radius: 0.5rem;
  background: #ffffff;
  padding: 0 0.5rem;
  font-weight: normal;
  max-width: 100%;
  font-style: italic;
}
.categoryFilterUsedItem .categoryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  min-width: 0;
  max-width: 100%;
}
