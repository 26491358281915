.preview {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
  flex: 1;
  min-height: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  position: relative;
}

.recordButtons {
  position: absolute;
  bottom: 0.5rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem;
  min-height: 3rem;

  .left {
    margin-right: auto;
  }

  .right {
    margin-left: auto;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  & > * {
    flex: 1;
  }
}

.recordButtonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.recordButton {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  margin: 0;
  padding: 0;
  background: red;
  color: white;
  border: solid 1rem white;
  transition: border-width 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  z-index: 1;
  outline: solid 0.3rem rgba(0, 0, 0, 0.3) !important;
  justify-content: space-between;

  &.recording {
    border-width: 0.2rem;

    &:after {
      z-index: 2;
      content: "";
      position: absolute;
      inset: 0.8rem;
      background: white;
    }
  }
}

.recordedVideoPreview {
  max-width: 80px;
  max-height: 3rem;
  display: block;
  flex: 1;
  outline: solid 0.3rem rgba(0, 0, 0, 0.3) !important;
  border-radius: 0.5rem;
}

.recordedVideoFull {
  max-width: 100%;
  max-height: 100%;
  display: block;
  flex: 1;
}

.hidden {
  display: none;
}

.videoResult {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  inset: 0;
}
@primary-color: rgb(25,61,113);