/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.content {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
}
.layoutContent {
  margin: 0.5rem;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
}
.mainContent {
  height: 100dvh;
}
.spinBox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
