@media (max-width: 560px) {
  .responsiveCalendar {
    & :global(.ant-picker-panels) {
      width: 320px;
      flex-direction: column;
    }
    & :global(.ant-calendar-range-part) {
      width: 100%;
    }
  }
}

@primary-color: rgb(25,61,113);