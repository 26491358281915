/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.tree {
  composes: scrollbar from global;
}
.tree :global(.ant-tree-draggable-icon) {
  opacity: 1 !important;
  display: none;
}
.tree :global(.ant-tree-treenode::before) {
  display: none;
}
.tree :global(.ant-tree-treenode-selected) > * {
  background-color: #193d71 !important;
  color: #fff;
}
.tree :global(.ant-tree-treenode-selected) > *:last-child {
  padding-right: 0.5rem;
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.tree :global(.ant-tree-treenode-selected) > *:first-child {
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.tree :global(.ant-tree-list-holder) {
  height: var(--height);
}
.tree :global(.ant-tree-treenode):nth-child(2n) {
  background: linear-gradient(90deg, #f5f5f5 50%, rgba(240, 240, 240, 0) 100%);
}
.tree :global(.ant-tree-indent-unit) {
  width: 0.75rem;
}
.dropdown {
  display: inline-block;
  width: calc(100% - 30px);
}
.title {
  display: flex;
  align-items: center;
  min-height: 24px;
  font-weight: 600;
  min-width: 50px;
}
.titleLeft {
  flex: 1;
  display: flex;
  gap: 0.5rem;
  color: inherit;
  min-height: 24px;
  line-height: 24px;
  white-space: nowrap;
}
.linkTooltipSmall :global(.ant-tooltip-inner) {
  width: 300px;
}
.linkTooltipMedium :global(.ant-tooltip-inner) {
  width: 500px;
}
.linkTooltipLarge :global(.ant-tooltip-inner) {
  width: 700px;
}
