/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.selected {
  background-color: #b3b3b3;
}
.flag {
  vertical-align: center;
  font-size: 1em;
}
.groupTitle {
  cursor: default !important;
}
