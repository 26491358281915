.wrapper {
  min-width: 200px !important;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.userInfoSection {
  cursor: default;
  background: #ffffff !important;
}

.userIcon {
  display: block;
  text-align: center;
  padding: 0 0 0.5rem 0;
}

.username {
  display: block;
  text-align: center;
  padding: 0;
  height: 1.5em;
  line-height: 1.5em;
}

.dropdown {
  background: #ffffff;
  border-radius: 0.2rem;
  overflow: hidden;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  min-width: 10rem;
}

.divider {
  margin: 0;
}

.badge :global(.ant-badge-count) {
  transform: none;
  translate: none;
  right: 2px;
}

@primary-color: rgb(25,61,113);