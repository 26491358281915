.tag {
  min-height: 2rem;
  padding: 0.2rem;
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  margin: 0;
  gap: 0.2rem 0.5rem;
  flex-wrap: wrap;
}
@primary-color: rgb(25,61,113);