.projectSelectionLabel {
  & :global(.ant-form-item-required) {
    width: 100%;
  }

  & .labelText {
    flex-grow: 1;
  }
}

.identificationSelect {
  width: 100%;
}

@primary-color: rgb(25,61,113);