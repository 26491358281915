/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.filterDropdown {
  display: flex;
  flex-direction: column;
  max-height: min(30rem, 100vh);
  overflow: auto;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.filterDropdownFooter {
  border-top: solid 1px #f1f1f1;
  display: flex;
}
.clearFilter {
  text-align: right;
  margin-left: auto;
  justify-content: flex-end;
}
.filterButton {
  display: flex;
  align-items: center;
  border-radius: 2rem;
  padding-inline: 0.75rem;
}
.filterButton.isSet {
  background-color: #193d71;
  border-color: transparent;
  color: #ffffff;
}
.filterButton.isOpen {
  background-color: #fee7cc;
  border-color: transparent;
}
.filterButton > i:global(.anticon) {
  margin-left: 0.3em;
}
.filterButton .tag {
  border-radius: 0.25rem;
  background: #ffffff;
  color: #000000;
  font-weight: 600;
  padding: 0 0.4rem;
  font-size: 0.7rem;
  min-width: 1.2rem;
  text-align: center;
}
.filterValue {
  margin-left: 0.3em;
  font-weight: bold;
}
.icon {
  margin-left: 0.5em;
}
